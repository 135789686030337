.notification-dropdown-container{
  cursor: auto;
  z-index: 200;
  padding: 20px;
  position:absolute;
  top: 58px;
  right: -14px;
  width: 410px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  background: #F6F2FA;
  box-shadow: 3px 3px 10px 2px rgba(88, 46, 144, 0.2);
  border-radius: 10px;
  transition: 0.6s ease-out;
  * {
    user-select: none;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--palette-light-violet-200);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--palette-light-violet-500);
    opacity: 1;
    border-radius: 10px;
  }
  @media (max-width: 600px) {
    top: 41px;
    right: -150px;
  }
  @media (max-width: 450px) {
    top: 41px;
    right: -89px;
    width: 300px;
  }

  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #5E5873
    }
    div{
      width: 66px;
      height: 18px;
      background: var(--palette-light-violet-200);
      border-radius: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: var(--palette-dark-violet-300);
      }
    }
  }

  &__alert-box{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  &__alert{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    .txt{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    .switch{
      position: relative;
      display: inline-block;
      width: 42px;
      height: 24px;

      input{
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider{
        position: absolute;
        cursor: pointer;
        top: 0; bottom: 0;
        right: 0; left: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
      }

      .slider:before{
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 4px;
        bottom: 5px;
        background-color: #fff;
        transition: .4s;
        border-radius: 50%;
      }

      input:checked + .slider{
        background-color: #9E77D3;
      }
      input:checked + .slider:before{
        transform: translateX(19px);
      }
    }
  }

  .mark-as-read{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding-top: 20px;
    cursor: pointer;
    transition: 0.2s all;
    &:hover {
      color: var(--palette-dark-violet-300)
    }
  }
  &__btn{
    width: 100%;
    height: 43px;
    margin-top: 20px;
  }
}