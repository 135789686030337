.delete-doc-confirmation{
  h4{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
  }

  p{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--palette-secondary-500);
    margin: 10px 0 20px 0;
  }

  .delete-doc-action{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    @media (max-width: 340px) {
      flex-direction: column;
    }
    .primary-button_outlined {
      border-color: var(--palette-secondary-red);
      color: var(--palette-secondary-red);
      &:hover {
        color: #FEFEFE;
        background-color: var(--palette-secondary-red);
        border: 1px solid var(--palette-secondary-red);

      }
    }
  }
}