.passwordComponent {
    .input-primary-wrap {
        margin-bottom: 10px;
    }
    .formats {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.4s all;

        &.focused {
            opacity: 1;
            height: 144px;
        }
    }
    .formatElement {
        display: flex;
        align-items: center;

        & span {
            font-size: 16px;
        }
    }
}
