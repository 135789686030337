.avatarModal {
    position: fixed;
    overflow: auto;
    background-color: rgba($color: #000000, $alpha: 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10000;

    canvas {
        border-radius: 8px;
    }

    &_container {
        background-color: var(--palette-secondary-main-bg);
        padding: 45px 30px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &_title-and-close {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        align-items: center;

        h2 {
            margin: 0;
        }
    }
    &_btns {
        display: flex;
        gap: 20px;
    }
}
