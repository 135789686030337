.custom_textarea_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
    .custom_textarea {
        border-radius: 10px;
        border: 1px solid var(--palette-light-violet-700);
        padding: 20px;
        width: 100% !important;
        min-height: 115px;
        background: transparent;
        @media (max-width: 768px) {
            padding: 12px;
        }
        &.error_textarea {
            border: 1px solid var(--palette-secondary-red);
        }
        &:focus-visible {
            outline: none !important;
        }
    }
}
