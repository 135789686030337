.delete-account-page {
  margin-top: 40px;
  &__top {
    margin-bottom: 45px;
    @media (max-width: 360px) {
      margin-bottom: 30px;
    }

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 36px;
      @media (max-width: 360px) {
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--palette-secondary-500);
      @media (max-width: 360px) {
        font-weight: 700;
        font-size: 12px;
        line-height: 13px;
      }
    }
  }

  &__select {
    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 20px;
      @media (max-width: 360px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .select-box {
      display: flex;
      flex-direction: column;
      gap: 20px;



      input[type="checkbox"] {
        width: 16px;
        height: 16px;
      }
    }
  }

  .input-primary-wrap {
    width: 60%;
    margin-bottom: 25px;

    .delete-account-input {
      width: 60% !important;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
    .delete-account-other {
      display: block;
      width: 60% !important;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 60px;

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: var(--palette-secondary-500);
      @media (max-width: 360px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      flex-wrap: wrap;
    }

    .delete-account-button {
      color: var(--palette-secondary-red);
      border: 1px solid var(--palette-secondary-red);

      &:hover {
        background: var(--palette-secondary-red);
        color: #fefefe;
      }
    }

  }
}

.modal-body {
  max-width: 496px;
  @media (max-width: 652px) {
    width: 80vw;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    @media (max-width: 360px) {
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 20px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--palette-secondary-500);
    margin-bottom: 20px;
    @media (max-width: 360px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    @media (max-width: 360px) {
      justify-content: center;
    }

    .delete-account-button-modal {
      color: var(--palette-secondary-red);
      border: 1px solid var(--palette-secondary-red);

      &:hover {
        background: var(--palette-secondary-red);
        color: #fefefe;
      }
    }
  }

}
