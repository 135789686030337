.calendarPage {
    &.container {
        @media (max-width: 768px) {
            width: 100% !important;
            margin: 0 !important;
        }
    }
    .calendar_select-box {
        margin-bottom: 20px;
        @media (max-width: 768px) {
            margin: 0 10px 20px 10px;
        }
        .dropDown {
            min-width: 300px;
        }
    }
    .rbc-button-link {
        padding-bottom: 15px;
    }
    .calendar-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        flex-shrink: 0;
        @media (max-width: 768px) {
            gap: 10px;
        }
        .lg-btn {
            @media (max-width: 440px) {
                display: none;
            }
        }
        .sm-btn {
            padding: 5px;
            display: flex;
            @media (min-width: 441px) {
                display: none;
            }
        }
    }
    .calendarCard {
        border-radius: 10px;
        background-color: var(--palette-secondary-200);
        padding: 10px;
        @media (max-width: 768px) {
            border-radius: 0;
            padding: 10px 0;
            overflow-x: scroll;
        }
        .business_calendar {
            aspect-ratio: 10/8;
            //min-height: 600px;
            @media (max-width: 768px) {
                aspect-ratio: 10/12;
            }
        }
    }
    .rbc-month-row {
        overflow: unset;
    }
    .rbc-calendar {
        @media (max-width: 768px) {
            //margin: 0 -10px;
            border: none;
            align-items: flex-start;
            position: relative;
            min-width: 420px;
        }
    }
    .rbc-agenda-table {
        thead {
            tr {
                th:nth-child(2) {
                    display: none !important;
                }
            }
        }
        .rbc-agenda-time-cell {
            display: none !important;
        }
    }
    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        border-radius: 6px;
        //min-height: 50px;
        width: 100%;
        background-color: var(--palette-light-violet-200);
        padding: 0;
    }
    .rbc-event.rbc-selected,
    .rbc-day-slot .rbc-selected.rbc-background-event {
        background-color: var(--palette-light-violet-300);
    }
    .rbc-event:focus,
    .rbc-day-slot .rbc-background-event:focus {
        outline: 5px auto var(--palette-light-violet-300);
    }
    .rbc-day-slot .rbc-event,
    .rbc-day-slot .rbc-background-event {
        border: none;
        overflow: unset;
    }
    .rbc-timeslot-group {
        min-height: 60px;
    }
    .rbc-event-label {
        font-size: 14px;
        display: none;
    }
    .rbc-label {
        font-size: 14px;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
    .rbc-toolbar {
        justify-content: flex-start;
        gap: 10px;
        @media (max-width: 768px) {
            padding: 0 10px 0 10px;
            margin-left: -20px;
            max-width: 100vw;
            position: sticky;
            left: 0;
        }
    }
    .rbc-event-content {
        height: 100%;
    }

    .rbc-day-slot .rbc-events-container {
        margin-right: 2px;
    }
    .rbc-agenda-view {
        padding: 0 10px;
    }
    .rbc-day-slot .rbc-event-content {
        font-size: 20px;
    }
    .rbc-day-slot .rbc-background-event {
        opacity: 0.5;
    }
    .rbc-row-content {
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        z-index: auto;
    }
}
