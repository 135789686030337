@import 'palette';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*:focus,
*:focus-visible {
    outline: none !important;
}
html {
    font-size: 20px;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--palette-secondary-main-bg) !important;
}

p,
li,
a,
b,
input,
textarea {
    color: var(--palette-secondary-700);
    font-weight: 400;
    font-size: 0.9rem;
}
button {
    font-size: 0.7rem;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 0.6rem;
    }
}
b {
    font-weight: 600;
}
a {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Comfortaa', sans-serif;
}

h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.6rem;
    color: var(--palette-secondary-700);
    @media (max-width: 768px) {
        font-size: 1rem;
    }
}
h3 {
    font-size: 1.2rem;
}
h4 {
    font-size: 0.9rem;
}

.test {
    background: var(--palette-secondary-100);
}
.container {
    width: calc(100% - 70px);
    // max-width: 1216px;
    margin: 0 35px;
    @media (max-width: 768px) {
        margin: 0 20px;
        width: calc(100% - 40px);
    }
}
.Toastify__toast-container {
    z-index: 1000000000 !important;
}
.custom-toast {
    border-radius: 12px;
    .Toastify__progress-bar {
        background: var(--palette-light-violet-600);
    }
}
.d-none {
    display: none !important;
}
.w-100 {
    width: 100% !important;
}
.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.cursor-default {
    cursor: default !important;
}
.d-flex {
    display: flex !important;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.justify-content {
    &-between {
        justify-content: space-between !important;
    }
    &-end {
        justify-content: flex-end !important;
    }
    &-center {
        justify-content: center !important;
    }
    &-start {
        justify-content: flex-start !important;
    }
}
.justify-content-md {
    @media (max-width: 768px) {
        &-between {
            justify-content: space-between !important;
        }
        &-end {
            justify-content: flex-end !important;
        }
        &-center {
            justify-content: center !important;
        }
        &-start {
            justify-content: flex-start !important;
        }
    }
}
.align-items {
    &-center {
        align-items: center !important;
    }
    &-start {
        align-items: flex-start !important;
    }
    &-end {
        align-items: flex-end !important;
    }
}
.gap {
    &-5 {
        gap: 5px;
    }
    &-10 {
        gap: 10px;
        &-10 {
            gap: 10px 10px;
        }
        &-15 {
            gap: 10px 15px;
        }
        &-20 {
            gap: 10px 20px;
        }
    }
    &-15 {
        gap: 15px;
        &-10 {
            gap: 15px 10px;
        }
        &-15 {
            gap: 15px 15px;
        }
        &-20 {
            gap: 15px 20px;
        }
    }
    &-20 {
        gap: 20px;
        &-10 {
            gap: 20px 10px;
        }
        &-15 {
            gap: 20px 15px;
        }
        &-20 {
            gap: 20px 20px;
        }
    }
    &-x {
        &-10 {
            gap: 0 10px;
        }
        &-15 {
            gap: 0 15px;
        }
        &-20 {
            gap: 0 20px;
        }
    }
    &-y {
        &-10 {
            gap: 10px 0;
        }
        &-15 {
            gap: 15px 0;
        }
        &-20 {
            gap: 20px 0;
        }
    }
}

.flex-column {
    flex-direction: column !important;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-md-wrap {
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
}

.m {
    &10 {
        margin: 10px;
    }
    &-15 {
        margin: 15px;
    }
    &-20 {
        margin: 20px;
    }
    &t {
        &-10 {
            margin-top: 10px;
        }
        &-15 {
            margin-top: 15px;
        }
        &-20 {
            margin-top: 20px;
        }
    }
    &b {
        &-10 {
            margin-bottom: 10px;
        }
        &-15 {
            margin-bottom: 15px;
        }
        &-20 {
            margin-bottom: 20px;
        }
    }
    &l {
        &-10 {
            margin-left: 10px;
        }
        &-15 {
            margin-left: 15px;
        }
        &-20 {
            margin-left: 20px;
        }
    }
    &r {
        &-10 {
            margin-right: 10px;
        }
        &-15 {
            margin-right: 15px;
        }
        &-20 {
            margin-right: 20px;
        }
    }
    &x {
        &-10 {
            margin: 0 10px;
        }
        &-15 {
            margin: 0 15px;
        }
        &-20 {
            margin: 0 20px;
        }
    }
    &y {
        &-10 {
            margin: 10px 0;
        }
        &-15 {
            margin: 15px 0;
        }
        &-20 {
            margin: 20px 0;
        }
    }
}

.p {
    &-10 {
        padding: 10px;
    }
    &-15 {
        padding: 15px;
    }
    &-20 {
        padding: 20px;
    }
    &t {
        &-10 {
            padding-top: 10px;
        }
        &-15 {
            padding-top: 15px;
        }
        &-20 {
            padding-top: 20px;
        }
    }
    &b {
        &-10 {
            padding-bottom: 10px;
        }
        &-15 {
            padding-bottom: 15px;
        }
        &-20 {
            padding-bottom: 20px;
        }
    }
    &l {
        &-10 {
            padding-left: 10px;
        }
        &-15 {
            padding-left: 15px;
        }
        &-20 {
            padding-left: 20px;
        }
    }
    &r {
        &-10 {
            padding-right: 10px;
        }
        &-15 {
            padding-right: 15px;
        }
        &-20 {
            padding-right: 20px;
        }
    }
    &x {
        &-10 {
            padding: 0 10px;
        }
        &-15 {
            padding: 0 15px;
        }
        &-20 {
            padding: 0 20px;
        }
    }
    &y {
        &-10 {
            padding: 10px 0;
        }
        &-15 {
            padding: 15px 0;
        }
        &-20 {
            padding: 20px 0;
        }
    }
}
