.process-box-full{
  display: flex;
  flex-direction: column;
.process-box{
  display: grid;
  grid-template-columns: .5fr 1fr .5fr;
  justify-content: flex-start;
  margin-top: 100px;
  align-items: center;
  .process-navigation{
    display: flex;
    justify-content: center;
    align-items: center;

    .line{
      height: 5px;
      border: 0.5px solid transparent;
      width: 100%;
      background-color: #efefef;
      transition: .3s all;
      position: relative;
      animation-fill-mode: forwards;
    }
    .fill{
      position: relative;
      width: 0;
      height: 5px;
      transition: .5s all;
    }
    .fill-after{
      width: 100%;
      height: 5px;
      background-color:var(--palette-light-violet-700);
      transition: .5s all;
      padding: 0;
    }

    .step{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .active{
      border: 1px solid transparent;
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background: var(--palette-light-violet-700);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .5s, all;
      span{
        color: #efefef;
        font-weight: 400;
        font-size: 27px;
        line-height: 32px;
      }
    }

    .process-elem{
      border: 1px solid var(--palette-secondary-400);
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background: var(--palette-secondary-200);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .5s, all;
      span{
        color: var(--palette-secondary-400);
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 32px;
      }
    }

    .process-step{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;
      h4{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      .active-header{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: var(--palette-secondary-500);
      }
    }
  }


  .tab-content {
    display: none;
  }

  .active-content {
    display: block;
  }
}
  .process-nav-txt{
    display: flex;
    justify-content: space-around;
    margin-top: 7px;
    h4{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    .active-header{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--palette-secondary-500);
    }
  }
}
