.show-hide {
    display: none;
    @media (max-width: 680px) {
        color: #582e90;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
    }
}

.modalDownWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    > h4 {
        font-size: 18px;
    }

    @media (max-width: 680px) {
        &.active {
            display: none;
        }
    }
    .stats {
        width: 90%;
        display: flex;
        justify-content: space-between;
        > div {
            font-size: 14px;
            color: #848286;
        }

        &__nameImg {
            width: 70%;
            display: flex;
            align-items: center;
            img {
                padding-right: 5%;
            }
            p {
                font-size: 14px;
            }
        }
    }
}
