.forgot-password-modal {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 500px;

    h3,
    & > p {
        max-width: 420px;

        &.isOkMessage {
            max-width: auto;
        }
    }
    & > p {
        color: var(--palette-secondary-500);
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .back-btn {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        margin: 0 auto;
        span {
            color: var(--palette-secondary-600);
            margin-top: 3px;
            font-size: 16px;
            line-height: 16px;
        }
    }
}
