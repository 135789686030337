.dashboard-profile {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    max-width: 340px;
    gap: 30px;
    @media (max-width: 768px) {
        max-width: unset;
    }
    &.white-bg {
        min-width: 340px;
        @media (max-width: 768px) {
            min-width: unset;
        }
    }
    &.orange-bg {
        background: linear-gradient(
            111.26deg,
            #f4c038 23.9%,
            rgba(243, 234, 5, 0.6) 98.72%
        ) !important;
        span {
            font-size: 14px;
            padding: 10px 0;
        }
    }
    &-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background: white;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &-info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: space-between;

        &-top-box,
        &-bottom-box {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .view-calendar-btn {
                @media (max-width: 360px) {
                    padding: 10px 9px;
                }
            }
        }
        b {
            font-size: 24px;
            padding: 10px 0;
            color: var(--palette-dark-violet-100);
        }
    }
    img {
        position: relative;
        top: -25px;
        height: max-content;
    }
}
