.not-found-layout {
    background: url('../../assets/images/not-found-bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    position: fixed;
    padding: 20px;
    width: 100vw;
    top: 0;
    left: 0;
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 500px) {
        justify-content: center;
    }
    .error-code {
        display: flex;
        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 350px;
            line-height: 390px;
            color: var(--palette-secondary-500);
            @media (max-width: 768px) {
                font-size: 237px;
                line-height: 319px;
            }
            @media (max-width: 500px) {
                font-size: 129px;
                line-height: 168px;
            }
        }

        img {
            width: 317px;
            height: 397px;
            @media (max-width: 768px) {
                width: auto;
                height: 294px;
            }
            @media (max-width: 500px) {
                width: auto;
                height: 154px;
            }
        }
    }

    .text-first {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 45px;
        color: var(--palette-dark-violet-700);
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 35px;
            line-height: 40px;
        }
        @media (max-width: 500px) {
            font-size: 23px;
            line-height: 19px;
        }
    }

    .text-second {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        color: var(--palette-secondary-600);
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 19px;
            line-height: 15px;
        }
        @media (max-width: 500px) {
            font-size: 14px;
            line-height: 15px;
        }
    }
}
