.tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        opacity: 0;
        font-size: 12px;
        background-color: white;
        white-space: nowrap;
        border: 1px solid var(--palette-dark-violet-300);
        color: var(--palette-dark-violet-300);
        text-align: center;
        border-radius: 5px;
        padding: 10px 14px;
        position: absolute;
        z-index: 1;
        top: 110%;
        left: 50%;
        transform: translate(-50%, 0);
        transition: opacity 0.3s;
        &::after {
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent var(--palette-dark-violet-300) transparent;
        }
    }

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
        z-index: 300;
    }
}
