.dropdown-container {
    z-index: 200;
    padding: 20px;
    position: absolute;
    top: 58px;
    right: -2px;
    width: 380px;
    max-height: 310px;
    //overflow-y: auto;
    background: #fefefe;
    box-shadow: 3px 3px 10px 2px rgba(88, 46, 144, 0.2);
    border-radius: 10px;
    transition: 0.6s ease-out;
    @media (max-width: 760px) {
        top: 41px;
        right: -2px;
    }
    @media (max-width: 480px) {
        top: 42px;
        //right: -25px;
        width: 100%;
        position: fixed;
    }
    @media (max-width: 360px) {
        top: 42px;
        //right: 10px;
        width: 100%;
    }

    .dropdown-types {
        display: flex;
        flex-direction: column;

        .profile-type {
            display: flex;
            align-items: center;
            gap: 10px;

            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
            }

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: var(--palette-secondary-500);
            }
        }

        .create-account {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-top: 20px;

            .tooltip .tooltip-text {
                white-space: normal;
                width: 240px;
            }

            .tooltiptext {
                width: 250px;
                white-space: pre-wrap;
            }
            .dropdown-btn {
                padding: 5px 44px;
                border-radius: 10px;
                font-size: 14px;
                line-height: 19px;
                @media (max-width: 360px) {
                    font-size: 12px;
                    padding: 5px 24px;
                }
            }
        }
    }

    .logout-link {
        fill: #848286;
        stroke: #848286;
        color: #848286;
    }

    .profile-link,
    .logout-link {
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover {
            fill: #462573;
            stroke: #462573;
            color: #462573;
        }

        .profile {
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            @media (max-width: 360px) {
                font-size: 16px;
            }

            &:hover {
                color: #462573;
            }
        }

        .logout {
            font-size: 18px;
            line-height: 20px;
            width: 100%;
            color: var(--palette-secondary-500);
            fill: var(--palette-secondary-500);
            stroke: var(--palette-secondary-500);
            @media (max-width: 360px) {
                font-size: 16px;
            }

            &:hover {
                color: #462573;
            }
        }
    }

    .divider {
        border: 0.5px solid #e3d8f3;
        width: 100%;
        margin: 20px 0;
    }

    .dropdown-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}
