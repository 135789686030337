.notifications-wrapper {
  //padding-top: 50px;
  // @media (max-width: 768px) {
  //   padding-top: 150px;
  // }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .notification-card {
    margin: 20px 0;
  }

  .allread-btn {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.12em;
    margin-top: 10px;
  }
}
