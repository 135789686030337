.switch-input{
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;

  input{
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider{
    position: absolute;
    cursor: pointer;
    top: 0; bottom: 0;
    right: 0; left: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before{
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 5px;
    background-color: #fff;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider{
    background-color: #9E77D3;
  }
  input:checked + .slider:before{
    transform: translateX(19px);
  }
}