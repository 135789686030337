.statistic {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 840px) {
    min-width: 420px;
  }
  @media (max-width: 840px) {
    flex: auto;
    justify-content: flex-start;
  }
  .statistic-header {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 840px) {
      align-items: center;
    }
  }
  .statistic-info {
    padding-bottom: 10px;
    //display: flex;
    //flex-wrap: wrap;
    //gap: 15px;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    .info-block {
      display: flex;
      padding-top: 20px;
      align-items: center;
      gap: 10px;
      .numbers {
        display: flex;
        flex-direction: column;
        height: max-content;
        .number {
          position: relative;
          z-index: 0;
          min-width: 44px;
          display: flex;
          align-items: center;
          min-height: 27px;

        }
        span {
          text-transform: capitalize;
        }
      }
    }
  }
}