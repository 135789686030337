.masterModal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(4, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);

    &__content {
        width: 670px;
        padding: 45px;
        border-radius: 12px;
        background-color: #f7f4f9;
        transform: scale(0.5);
        transition: 0.4s all;

        @media (max-width: 740px) {
            width: 85%;
        }

        .header {
            display: flex;
            justify-content: space-between;
            @media (max-width: 550px) {
                font-size: 16px;
            }
        }
        .checkbox-text {
            display: flex;
            align-items: flex-start;
            margin-top: 15px;
            margin-bottom: 15px;
            position: relative;

            .checkbox {
                position: absolute;
                top: 5px;
                left: 5px;
                width: 20px;
                height: 20px;
                opacity: 0;
            }
            p {
                padding-left: 10px;
            }
        }
        .link-text {
            padding-left: 35px;
            color: #848286;
            span {
                color: #3a393a;
                border-bottom: 1px solid #3a393a;
            }
        }
        .btns {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            @media (max-width: 550px) {
                flex-direction: column;
            }
            div {
                padding-right: 15px;
                @media (max-width: 550px) {
                    display: none;
                }
            }
        }
    }
    &__content.active {
        box-shadow: -2px 0px 20px rgb(0 0 0 / 10%);
        display: flex;
        transform: scale(1);
        overflow-y: scroll;

        @media (max-width: 680px) {
            display: block;
        }
    }
}

.masterModal.active {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
    background-color: #05010b8f;
}
