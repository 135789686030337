// styles for collapsed menu

.sidebar-container {
  position: sticky;
  top: 0;
  width: 80px;
  height: 100vh;
  background-color: #fefefe;
  display: flex;
  overflow: auto;
  flex-direction: column !important;
  z-index: 300;
  padding: 0 18px;
  overflow-x: hidden;
  transition: 0.4s;
  .mobileIcon {
    display: none;
  }
  &::-webkit-scrollbar {
    border-radius: 100px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e3e3e3;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--palette-light-violet-500);
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--palette-dark-violet-100);
  }
  @media (max-width: 1024px) {
    display: none;
  }

  .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //overflow: hidden;
  }

  .logo-block {
    transition: 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px 31px 2px;

    .rollup {
      display: none;
    }

    .close-sidebar {
      display: none;
    }
  }

  .rollup-reverse{
    display: none;
  }

  ul{
    list-style-type: none;
  }

  li {
    text-decoration: none;

    .link {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      justify-items: center;
      column-gap: 1.75rem;
      padding: 9px;
      transition: 0.5s;
      cursor: pointer;
      border-radius: 8px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }

      div {
        padding-top: 4px;
      }

      &:hover {
        background: var(--palette-dark-violet-100);
        border-radius: 8px;
        transition: 0.5s ease;

        div {
          fill: var(--palette-secondary-200);
          stroke: var(--palette-secondary-200);
          color: var(--palette-secondary-200);
          padding-top: 4px;
        }

        span {
          color: var(--palette-secondary-200);
        }
      }
    }

    .log-out-box {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      justify-items: center;
      column-gap: 1.75rem;
      padding: 10px;
      transition: 0.5s;
      cursor: pointer;
      border-radius: 8px;

      .log-out-btn {
        background: none;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #292929;
        cursor: pointer;
      }
      &:hover {
        background: var(--palette-dark-violet-100);
        border-radius: 8px;
        transition: 0.5s ease;


        fill: var(--palette-secondary-200);
        stroke: var(--palette-secondary-200);
        color: #FFFFFF;
        padding: 10px;

        .log-out-btn {
          color: #FEFEFE;
        }
        span {
          color: var(--palette-secondary-200);
        }
      }
    }

  }

  .divider {
    border: 0.5px solid var(--palette-light-violet-200);
    margin: 30px 0;
  }

  .active {
    background: var(--palette-dark-violet-500);

    div {
      fill: var(--palette-secondary-200);
      stroke: var(--palette-secondary-200);
      color: var(--palette-secondary-200);
    }

    span {
      color: var(--palette-secondary-200);
    }
  }

  .settings {
    margin-top: 90px;
  }


}

.sidebar-container:hover {
  width: 280px;
  transition: 0.4s;

  .rollup-reverse {
    display: flex;
  }
}

// styles for expanded menu
.expander {
  width: 280px;
  padding: 0;

  .logo-block__expand {
    justify-content: space-between;
    padding: 32px;
    transition: 0.5s;
    .rollup {
      display: flex;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .close-sidebar {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }

  .rollup-reverse-none{
    display: none;
  }

  li {
    .link {
      padding: 10px 0 10px 32px;
      margin-right: 30px;
      border-radius: 0 25px 25px 0;

      &:hover {
        background: var(--palette-light-violet-700);
        border-radius: 0 25px 25px 0;
        transition: 0.5s ease;

        div {
          fill: var(--palette-secondary-200);
          stroke: var(--palette-secondary-200);
          color: var(--palette-secondary-200);
        }

        span {
          color: var(--palette-secondary-200);
        }
      }
    }

    .active {
      background: var(--palette-dark-violet-100);
      border-radius: 0 25px 25px 0;

      div {
        fill: var(--palette-secondary-200);
        stroke: var(--palette-secondary-200);
        color: var(--palette-secondary-200);
      }

      span {
        color: var(--palette-secondary-200);
      }
    }

    .log-out-box {
      display: flex;
      align-items: center;
      gap: 1.75rem;
      padding: 10px 0 10px 32px;
      margin-right: 30px;
      border-radius: 0 25px 25px 0;

      .log-out-btn {
        background: none;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #292929;

        &:hover {
          color: #FEFEFE;
        }
      }

      &:hover {
        background: var(--palette-light-violet-700);
        padding: 10px 0 10px 32px;
        border-radius: 0 25px 25px 0;
        transition: 0.5s ease;
        color: #FEFEFE;

        .log-out-btn {
          color: #FEFEFE;
        }
      }
    }

  }
}

// styles for mobile version
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(41, 41, 41, 0.24);
  z-index: 500;
  .mobileIcon {
    display: none;
  }
  .sidebar-container-mobile {
    display: none;
    @media (max-width: 1024px) {
      width: 280px;
      height: 100%;
      padding: 0;
      position: sticky;
      top: 0;
      min-height: 100vh;
      background-color: #fefefe;
      display: flex;
      overflow: auto;
      flex-direction: column !important;
      z-index: 300;
      transition: all 1s;
      opacity: 1;
      right: 280px;
      .nav {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .logo-block {
        display: grid;
        grid-template-columns: max-content max-content;
        column-gap: 0.75rem;
        justify-content: space-between;
        padding: 32px;
        align-items: center;

        .rollup {
          display: none;
        }

        .close-sidebar {
          transition: all 1s;
          left: -280px;
        }
      }

      li {
        text-decoration: none;
        transition: all 1s;
        .link {
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          justify-items: center;
          column-gap: 0.75rem;
          transition: 1s;
          cursor: pointer;
          padding: 10px 0 10px 32px;
          margin-right: 30px;
          border-radius: 0 25px 25px 0;

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }

          div {
            padding-top: 4px;
          }

          &:hover {
            background: var(--palette-light-violet-700);
            border-radius: 0 25px 25px 0;
            transition: 1s ease;

            div {
              fill: var(--palette-secondary-200);
              stroke: var(--palette-secondary-200);
              color: var(--palette-secondary-200);
              padding-top: 4px;
            }

            span {
              color: var(--palette-secondary-200);
            }
          }
        }
      }

      .divider {
        border: 0.5px solid var(--palette-light-violet-200);
        margin: 32px 0;
      }

      .active {
        background: var(--palette-light-violet-700);

        div {
          fill: var(--palette-secondary-200);
          stroke: var(--palette-secondary-200);
          color: var(--palette-secondary-200);
        }

        span {
          color: var(--palette-secondary-200);
        }
      }

      .settings {
        margin-top: 90px;
      }
    }
  }
}

.burger {
  display: none;
  @media (max-width: 1024px) {
    position: absolute;
    display: flex;
    z-index: 300;
    top: 0;
    left: 0;
    //padding: 32px;
    width: 45px;
    height: 45px;
    background: #fefefe;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 20px 35px;
  }
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    margin: 20px;
    div {
      width: 24px;
      height: 24px;
    }
  }
}

