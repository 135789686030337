.check-email {
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 20px;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #848286;
    }

    @media (max-width: 570px) {
        h3 {
            font-size: 24px;
        }
    }

    @media (max-width: 380px) {
        h3 {
            max-width: 250px;
        }
    }
}
.signUp-container {
    width: 100%;
    position: absolute;
    padding: 50px 20px;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.8s left ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--palette-light-violet-200);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--palette-light-violet-500);
        opacity: 1;
        border-radius: 10px;
    }
    .arrow-back {
        position: absolute;
        top: 30px;
        left: 30px;

        @media (max-width: 768px) {
            left: 20px;
        }
    }

    .sign-up-tab {
        // padding-top: 128px;
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 35px;
        @media (max-width: 460px) {
            gap: 40px;
            overflow: auto;
        }
        li {
            list-style-type: none;
            button {
                cursor: pointer;
                background: none;
                font-family: 'Comfortaa', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 27px;
                color: #3a393a;
                border: 1px solid transparent;
                @media (max-width: 953px) {
                    font-size: 17px;
                }
                @media (max-width: 360px) {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 13px;
                }
                &:hover {
                    padding-bottom: 5px;
                    border-bottom: 2px solid var(--palette-dark-violet-300);
                }
            }
            .active {
                font-family: 'Comfortaa', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 27px;
                padding-bottom: 5px;
                color: var(--palette-dark-violet-300);
                border-bottom: 2px solid var(--palette-dark-violet-300);
                @media (max-width: 953px) {
                    font-size: 17px;
                }
                @media (max-width: 360px) {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 13px;
                }
            }
        }
    }
    .mobile_info {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        align-items: center;
        width: 100%;
        display: flex;
        p {
            display: none;
        }

        @media (max-width: 768px) {
            p {
                display: block;
            }
        }
        span {
            cursor: pointer;
            color: var(--palette-dark-violet-300);
        }
    }
    .signUp {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 420px;
        margin: 0 auto;

        &.sending {
            opacity: 0.6;
            user-select: none;
            pointer-events: none;
        }
        .button {
            margin-top: 10px;
            width: 100%;
        }
        .submitTerms-block {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            .check-box_required {
                position: absolute;
                color: var(--palette-secondary-red);
                left: 0;
                bottom: -18px;
                font-size: 0.6rem;
                width: 100vw;
            }

            @media (max-width: 320px) {
                .check-box_required {
                    font-size: 0.5rem;
                }
            }

            .checkbox-container {
                position: relative;
                input {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    left: 0;
                }
            }
        }
        .submitTerms {
            text-align: start;
            font-size: 14px;

            span {
                color: var(--palette-dark-violet-300);
                font-size: 14px;
            }
        }

        .checkbox-container {
            position: relative;
            input {
                cursor: pointer;
                position: absolute;
                opacity: 0;
            }
        }
    }
}
