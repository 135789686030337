.notification-card {
    width: 100%;
    background: #fefefe;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: 0.2s all;
    &:not([aria-disabled='true']) {
        cursor: pointer;
        &:hover {
            border: 1px solid var(--palette-dark-violet-300);
            @media (max-width: 768px) {
                border-color: transparent;
            }
        }
    }
    * {
        user-select: none;
    }
    .notification-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 30px 20px;
        border-bottom: 1px solid var(--palette-light-violet-200);
        @media (max-width: 740px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
        .icon {
            cursor: auto;
        }
        .notification-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 15px;

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 27px;
                text-transform: capitalize;
                color: var(--palette-secondary-700);
                @media (max-width: 740px) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
        .circle {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: var(--palette-light-violet-200);
            &-active {
                background: var(--palette-dark-violet-300);
            }
        }
    }

    .notification-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 20px 20px 50px 20px;
        .full-text_box {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 0 20px 30px;
            .linked {
                padding-top: 20px;
            }
        }
        .notification-text {
            -webkit-line-clamp: 2; /* Число отображаемых строк */
            display: -webkit-box; /* Включаем флексбоксы */
            -webkit-box-orient: vertical; /* Вертикальная ориентация */
            overflow: hidden;
        }
    }

    .notification-hours {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: var(--palette-secondary-500);
        padding: 0 20px 20px 20px;
    }

    .details-btn {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.12em;
        margin: 0 0 20px 20px;
    }
}
