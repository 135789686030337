.input-primary-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 7px;
    .clear {
        top: 25%;
        bottom: auto !important;
        transform: none !important;
    }
    .hint {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: var(--palette-secondary-500);
    }

    &_label {
        color: var(--palette-secondary-600);
        font-weight: 500;
        margin-left: 1px;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .input-primary {
        outline: none;
        background-color: transparent;
        padding: 9px 19px;
        border: 1px solid var(--palette-light-violet-700);
        border-radius: 10px;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        &::placeholder {
            color: #938f8c;
        }
        &__datetime {
            padding-right: 0;
        }
        &__error {
            border-color: #ff2200;
            &::placeholder {
                color: #ff2200;
            }
            &:hover {
                border-color: #ff2200 !important;
            }
            &:focus {
                border-color: #ff2200 !important;
            }
        }
        &__withIcon {
            padding-left: 53px;
        }
        &:hover {
            border-color: var(--palette-dark-violet-300);
        }
        &:focus {
            border-color: var(--palette-dark-violet-500);
        }
        &::-webkit-calendar-picker-indicator {
            display: none;
        }
    }
    & > span {
        position: absolute;
        color: #ff2200;
        left: 0;
        white-space: nowrap;
        bottom: -18px;
        font-size: 0.6rem;
    }
    & .icon {
        position: absolute;
        right: 20px;
        bottom: 50%;
        transform: translateY(25px);
    }
}
