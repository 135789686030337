.modalReviews {
    width: 100%;
    padding: 35px;

    @media (max-width: 800px) {
        padding: 20px;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        h1 {
            @media (max-width: 1440px) {
                font-size: 32px;
                font-weight: 600;
            }
            @media (max-width: 360px) {
                font-size: 20px;
            }
        }
        .filter {
            cursor: pointer;
            padding: 6px 20px;
            background: #582e90;
            border-radius: 10px;
            color: #fefefe;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
            border: none;
        }
        .filter-content {
            width: 169px;
            border-radius: 8px;
            background-color: #ffffff;
            position: absolute;
            top: 40px;
            right: 17px;
            //border: 1px solid #582e90;
            box-shadow: -2px 0px 20px rgb(0 0 0 / 10%);
            &__star {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                .checkbox {
                    display: flex;
                    align-items: center;
                }
                &:hover{
                    background-color: #9e77d3;
                    span{
                        color: #FEFEFE;
                    }
                    &:first-child {
                        border-radius: 8px 8px 0 0;
                    }
                    &:last-child{
                        border-radius:  0 0 8px 8px;
                    }
                }
            }
            div {
                cursor: pointer;
                font-style: normal;
            }

            .applyFilters {
                border-top: 1px solid #582e90;
                font-weight: 400;
                font-size: 18px;
                color: #582e90;
                padding: 6px 20px;
            }

            .applyFilters:hover {
                color: #fefefe;
                background-color: #9e77d3;
                border-radius:  0 0 8px 8px;
            }
        }
    }

    .card:hover {
        border: 1px solid #e0e7ed;
        transition: 1s;
    }
    .card {
        cursor: pointer;
        border-radius: 8px;
        background-color: #fefefe;
        width: 100%;
        min-width: 395px;
        min-height: 202px;
        margin-bottom: 10px;
        @media (max-width: 720px) {
            width: 100%;
            min-width: 300px;
        }

        .sectionOne {
            padding: 20px;
            border-bottom: 1px solid #e3d8f3;
            .sectionImgName {
                display: flex;
                align-items: center;
                img {
                    border-radius: 15px;
                    margin-right: 6px;
                    margin-bottom: 5px;
                }
            }
        }
        .sectionTwo {
            padding: 20px;
            > p {
                margin-bottom: 10px;
                color: #292929;
                font-size: 14px;
            }
            .sectionTwoText {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &__left {
                    display: flex;
                    font-size: 12px;
                    color: #848286;
                    p {
                        padding-right: 10px;
                    }
                }
                p {
                    font-size: 12px;
                    color: #848286;
                }
                .published {
                    font-weight: 600;
                }
            }
        }
    }

    .background404 {
        width: 100%;
        padding: 26px 0px;
        background-color: #fbf9fb;
        text-align: center;
        border-radius: 8px;
        img {
            width: 35%;
            @media (max-width: 1440px) {
                width: 30%;
            }
        }
        p {
            width: 100%;
            font-weight: 600;
            font-size: 20px;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
        }
    }
}
