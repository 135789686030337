.changePassword {
    padding: 35px;
    background-color: var(--palette-secondary-200);
    border-radius: 10px;
    margin-bottom: 200px;
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        width: 100%;
        max-width: 500px;
        transition: opacity 0.2s;

        @media (max-width: 768px) {
            gap: 20px;

            button {
                margin-top: 10px;
            }
            .input-primary-wrap_label {
                font-size: 14px;
            }
            input {
                font-size: 16px;
                line-height: 23px;
            }
        }
    }
    .formSending {
        opacity: 0.7;
        user-select: none;
        pointer-events: none;
    }
}
