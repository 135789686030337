.toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 20px;
    cursor: pointer;
}

.modalButtons {
    text-align: center;

    p {
        padding-top: 4px;
        font-size: 14px;
    }
}

.toggle-btn-off:hover {
    background: #9e77d3;
}
.toggle-btn-on {
    background: #582e90;
}

.toggle-btn-off {
    background: #ffffff;
}

.bookingsActive {
    color: #582e90;
}

.hover {
    color: #9e77d3;
}

.reviewsActive {
    color: #582e90;
}
