.loginPage {
    width: 100%;
    height: 100vh;
    position: relative;
    // * {
    //     user-select: none;
    // }
    &_info {
        height: 100vh;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 100;
        width: 50%;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        transition: 0.8s all ease-in-out;
        @media (max-width: 768px) {
            display: none;
        }
        * {
            transition: 0.8s left ease-in-out;
        }

        & > img {
            width: 100vw;
            // max-width: ;
            height: 100vh;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: -20vw;
        }

        .info_container {
            width: 100vw;
            height: 100vh;
            display: grid;
            grid-template-columns: 50% 50%;
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;

            .information {
                display: flex;
                flex-direction: column;
                gap: 30px;
                align-items: center;
                margin: 0 auto;
                transition: 2s opacity;
                h2 {
                    font-size: 40px;
                }
                & > p {
                    margin-top: 15px;
                }

                .icon {
                    margin-top: 70px;
                }
            }
            .for_sign-in {
                opacity: 0;
            }
            .for_sign-up {
                opacity: 1;
            }
        }
    }
    .move-to-rigth {
        left: 50%;
        & > img {
            left: -50vw;
        }

        .info_container {
            left: -50vw;
        }
        .for_sign-in {
            opacity: 1;
        }
        .for_sign-up {
            opacity: 0;
        }
    }

    //**************for forms**************//
    &_form {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        padding: 50px;
        overflow: hidden;
        transition: 0.8s all ease-in-out;
        @media (max-width: 768px) {
            position: relative;
            padding-bottom: 0;
            width: 100%;
            left: 0;
        }
        .signUp_or {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding-top: 30px;
            width: 100%;
            max-width: 420px;
            margin: 0 auto;
            align-items: center;

            &-lines {
                display: flex;
                gap: 20px;
                align-items: center;
                flex-wrap: nowrap;
                width: 100%;
                max-width: 420px;
                margin: 0 auto;

                div {
                    height: 1px;
                    width: 100%;
                    background-color: var(--palette-light-violet-200);
                }

                p {
                    line-height: 25px;
                }
            }

            &-socials {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 10px;

                .social {
                    display: flex;
                    padding: 10px;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    border-radius: 8px;
                    cursor: pointer;

                    &_block {
                        width: 100%;
                        max-width: 228px;
                        display: flex;
                        gap: 20px;
                        justify-content: flex-start;
                        align-items: center;
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
            &-partner {
                background-color: white;
                border-radius: 8px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px;
                justify-content: center;

                h4 {
                    font-size: 21px;
                    text-align: center;
                    font-weight: 600;
                }
                p {
                    font-size: 16px;
                    text-align: center;
                }
                a {
                    color: var(--palette-dark-violet-300);
                }
            }
        }
    }
    .mobileBtn {
        display: none;
        @media (max-width: 768px) {
            display: block;
            width: 100%;
        }
    }
}
.move-to-left {
    left: 0;
    .signUp-container {
        left: 100%;
    }
    .signIn-container {
        left: 0;
    }
}
.sign-logo {
    @media (min-width: 768px) {
        display: none;
    }
}
