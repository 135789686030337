.delete-option{
  display: flex;
  cursor: pointer;
  user-select: none;
  gap: 5px;
    span{
      padding-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 360px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
}