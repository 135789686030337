.primary-button {
    padding: 10px 34px;
    color: var(--palette-secondary-100);
    background-color: var(--palette-dark-violet-300);
    border: 1px solid var(--palette-dark-violet-300);
    border-radius: 12px;
    transition: 0.15s all;
    position: relative;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;

    &_outlined {
        background-color: transparent;
        color: var(--palette-dark-violet-300);
    }

    &:hover {
        color: var(--palette-secondary-100);
        background-color: var(--palette-dark-violet-400);
        border: 1px solid var(--palette-dark-violet-400);
    }
    &:active {
        transform: scale(0.99);
        color: var(--palette-secondary-100);
        background-color: var(--palette-dark-violet-500);
        border: 1px solid var(--palette-dark-violet-500);
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.4;
        cursor: auto;
    }
    &:focus {
        outline: none;
    }
}
.button_loading {
    color: transparent !important;
}
.danger_button {
    color: var(--palette-secondary-red);
    border: 1px solid var(--palette-secondary-red);

    &:hover {
        background: var(--palette-secondary-red);
        color: #fefefe;
    }

}
