.review-details-wrapper {
    padding-top: 50px;

    .tooltiptext {
        width: 280px;
        white-space: pre-wrap;
        line-height: 19.1px;
        text-align: start;
        font-size: 14px;
        left: 120%;
    }
    .fixed-review {
        position: fixed;
        top: 0;
        z-index: 200;
        overflow-y: hidden;
        padding-top: 20px;
        width: inherit;
        max-width: inherit;
        background: var(--palette-secondary-main-bg);
        @media (max-width: 768px) {
            margin-top: 125px;
        }
    }
    .replies {
        width: 100%;
        padding-top: 50px;
    }
    .head-title {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 20px;
        }
    }
    .line {
        border: 1px solid var(--palette-light-violet-700);
        height: 30px;
        width: 1px;
        margin-left: 35px;
    }
    .reply-editor {
        border: 1px solid #9e77d3;
        border-radius: 8px;
        background: white;
        overflow: hidden;
        &.disabled {
            pointer-events: none;
            user-select: none;
            opacity: 0.4;
        }
        .reply-editor_title {
            width: 100%;
            background: #f1ecf9;
            padding: 20px;
            border-bottom: 1px solid #9e77d3;
            span {
                color: #292929;
                font-family: 'Comfortaa', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
            }
        }

        textarea {
            resize: none;
            width: 100%;
            min-height: 200px;
            border: none;
            padding: 20px;

            &:focus-visible {
                outline: none !important;
            }
        }
        .reply-editor_bottom {
            width: 100%;
            background: #f1ecf9;
            padding: 10px 20px;
            border-top: 1px solid #9e77d3;
            display: flex;
            justify-content: flex-end;
        }
    }
}
