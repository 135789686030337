.verification-top{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 45px;
}
.verification-document_a {
  font-family: 'Comfortaa',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  color: var(--palette-dark-violet-300);
 margin-bottom: 10px;
  text-decoration: underline;
}
.verification-document_title {
  font-family: 'Comfortaa',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--palette-secondary-600);
  margin-bottom: 10px;
  margin-top: 30px;
}

.verification-name {
  font-family: 'Comfortaa',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 30px;
  margin-top: 40px;
}
.verification-btn{
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 30px;
  justify-content: flex-start;
  gap: 30px;
}
.verification-profile {
  padding-bottom:20px ;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;

  .info-block {
    margin-top: 30px;
    span{
      color:  var(--palette-secondary-500);
      font-size: 18px;
    }
    p{
      margin-top: 5px;

      font-size: 20px;
    }
  }
}
.verification-res{
  width: 500px;
  h4{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
  }

  span{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #848286;
  }

  @media (max-width: 570px) {
    width: auto;

    h4 {
      font-size: 24px;
    }
  }

  @media (max-width: 430px) {
    h4 {
      max-width: 75vw;
    }
  }


}