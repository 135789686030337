.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #582E90;
  transition: 0.3s;
  padding-right: 10px;

  @media (max-width: 768px) {
    font-family: 'Comfortaa', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13.0909px;
    line-height: 15px;
    color: #582E90;
    width: 150px;
  }
}
.wrap-text:hover {
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  white-space: unset;
  text-align: end;
  max-height: 30px;
  overflow: unset;
  //padding-right: 5px;
  transition: 0.3s;
}
.select-address {
  @media (max-width: 768px) {
    font-family: 'Comfortaa', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13.0909px;
    line-height: 15px;
    color: #582E90;
    margin-right: 10px;
    transition: 0.3s;
  }
}

