.reactionsBtn {
    position: relative;
    align-self: flex-end;
    -webkit-align-self: flex-end;
    display: flex;
    justify-content: center;
    // cursor: pointer;
    border-radius: 20px;
    padding: 0 11px;
    min-width: 69px;
    gap: 5px;
    height: 34px;
    border: 1px solid var(--palette-light-violet-400);
    transition: 0.3s all;

    p {
        font-size: 14px;
        line-height: 34px;
        color: var(--palette-dark-violet-300);
    }
    .hover_container {
        position: absolute;
        cursor: inherit;
        bottom: 30px;
        right: 0px;
        z-index: -1;
        padding-bottom: 10px;
        opacity: 0;
        transition: 0.3s all;
    }
    .reactions {
        user-select: none;
        display: flex;
        background-color: white;
        border-radius: 100px;
        border: 1px solid var(--palette-dark-violet-300);
        // height: 40px;
        justify-content: center;
        align-items: center;
        padding: 3px 17px;
        padding-top: 0px;
        gap: 7px;

        .reaction {
            pointer-events: none;

            display: flex;
            flex-direction: column;
            text-align: center;

            p {
                font-size: 12px;
                line-height: 12px;
            }
        }
        .reaction img {
            transition: 0.3s all;

            &:hover {
                transform: scale(1.3);
            }
        }
    }

    &:hover {
        border-color: var(--palette-dark-violet-100);

        .hover_container {
            z-index: 1;
            opacity: 1;
        }
    }
}
