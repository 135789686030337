.clientsList {
    min-height: calc(100vh - 150px);

    .input-primary-wrap {
        margin-bottom: 30px;
        @media (max-width: 740px) {
            border-radius: 10px;
            background-color: #fefefe;
        }
        .input-primary__withIcon {
            @media (max-width: 360px) {
                font-size: 12px;
            }
        }
    }

    &__background {
        background: #fefefe;
        padding: 35px;
        border-radius: 10px;
        margin-bottom: 35px;
        @media (max-width: 740px) {
            background: transparent;
            padding: 0;
        }
    }

    .header {
        margin-bottom: 30px;
    }

    .dataTableWrapper {
        border-radius: 10px;
        & > div {
            & > div {
                display: block;
            }
        }
        @media (max-width: 740px) {
            display: none;
        }
    }

    .accordionWrapper {
        display: none;
        @media (max-width: 740px) {
            display: block;
        }
    }

    .clientsPhoto,
    .defaultClientsPhoto {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
    }

    .sc-dmRaPn {
        border: 1px solid #e0e7ed;
    }

    .sc-dkzDqf {
        font-size: 14px;
        font-weight: 600;
        background: #f9f9f9;
        color: #848286;
    }

    .paginationDiv {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__text {
            font-size: 12px;
            @media (max-width: 740px) {
                display: none;
            }
        }
        .ant-pagination {
            width: 50%;
            justify-content: end;
            padding-right: 3%;
            @media (max-width: 500px) {
                width: 100%;
                padding-right: 0;
                justify-content: center;
            }
        }
    }
    .logicOfData {
        display: flex;
        align-items: center;
        pointer-events: none;
    }
    .rdt_TableRow:hover {
        background-color: #f1ecf9;
    }
}
