.sales-by-client {
  max-height: 100%;
  &-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 10px;
  }
  &-table {
    max-height: 600px;
    overflow: auto;
    &::-webkit-scrollbar {
      border-radius: 100px;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #e3e3e3;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--palette-light-violet-500);
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--palette-dark-violet-100);
    }
  }
  .logicOfData {
    display: flex;
    align-items: center;
    gap: 10px;
    pointer-events: none;
    p {
      font-size: 14px;
    }
    .defaultClientsPhoto {
      width: 30px;
    }
  }
    .sc-dmRaPn {
    border: 1px solid #e0e7ed;
  }

  .sc-dkzDqf {
    font-size: 14px;
    font-weight: 600;
    background: #f9f9f9;
    color: #848286;
  }
}