.modal-bg {
    position: fixed;
    overflow: auto;
    padding: 20px 10px;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(5, 1, 11, 0.56);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-wrapper {
        background-color: var(--palette-secondary-main-bg);
        border-radius: 8px;
        padding: 45px;
        position: relative;
        max-width: 100%;

        @media (max-width: 768px) {
            padding: 30px;
        }
        @media (max-width: 550px) {
            padding: 30px 20px;
        }

        .close {
            position: absolute;
            top: 45px;
            right: 45px;
            z-index: 10;
            @media (max-width: 768px) {
                top: 30px;
                right: 30px;
            }
            @media (max-width: 550px) {
                top: 30px;
                right: 20px;
            }
        }
    }
}
