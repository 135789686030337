.dashboard-page {
    padding-bottom: 60px;
    .white-card {
        background-color: white;
        padding: 20px;
        border-radius: 6px;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
        h4 {
            font-weight: 500;
        }
        span {
            font-size: 12px;
            color: var(--palette-secondary-500);
        }
    }
    .head-box {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 10px;
        @media (max-width: 840px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .bottom-box {
        padding-top: 10px;
        display: grid;
        grid-template-columns: calc(50% - 5px) calc(50% - 5px);
        width: 100%;
        gap: 10px;
        @media (max-width: 768px) {
            grid-template-columns: 100%;
        }
    }
}
