.topbar {
    // position: fixed;
    width: calc(100% - 70px);
    margin: 20px 35px 45px 35px;
    display: flex;
    justify-content: space-between;
    height: 46px;
    z-index: 200;
    @media (max-width: 768px) {
        width: calc(100% - 40px);
        margin: 20px;

    }

    @media (max-width: 480px) {
        display: flex;
        justify-content: space-around;
        gap: 28px;
    }
    &-left {
        display: flex;
        gap: 20px;
        align-items: center;
        @media (max-width: 1024px) {
            align-items: start;
        }

        .mobile-forBurger {
            display: none;
            @media (max-width: 1024px) {
                display: block;
                width: 45px;
                height: 45px;
            }
            @media (max-width: 768px) {
                width: 32px;
                height: 32px;
            }
        }
    }


    .topbar-location {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fefefe;
        height: 46px;
        width: 46px;
        border-radius: 100px;
        cursor: pointer;
        &:hover {
            background-color: var(--palette-light-violet-700);
            color: white;
        }
        @media (max-width: 768px) {
            height: 32px;
            width: 32px;
            padding: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
        @media (max-width: 480px) {
            height: 32px;
            width: 32px;
            padding: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            //display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: var(--palette-dark-violet-300);
            @media (max-width: 768px) {
                font-weight: 700;
                font-size: 12px;
                line-height: 13px;
            }
            @media (max-width: 480px) {
                display: none;
            }
        }
        .refresh {
            @media (max-width: 480px) {
                display: none;
            }
        }

        .mobile-location {
            display: none;
            @media (max-width: 480px) {
                display: flex;
                //justify-content: center;
                //align-items: center;
            }
        }
    }

    .icon {
        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobileIcon {
        display: none;
        @media (max-width: 768px) {
            display: flex;
        }
    }

    .topbar-user-info {
        display: flex;
        gap: 20px;
        @media (max-width: 480px) {
            gap: 28px;
        }

        .notification-dropdown {
            position: relative;
            cursor: pointer;
        }

        .icon-bg {
            height: 48px;
            width: 48px;
            background: #fefefe;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media (max-width: 768px) {
                width: 32px;
                height: 32px;
            }
            &:hover {
                background: var(--palette-light-violet-700);
                fill: var(--palette-secondary-200);
                stroke: var(--palette-secondary-200);
                color: var(--palette-secondary-200);
            }

            .badge {
                position: absolute;
                right: -7px;
                top: -5px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #ff2200;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: 768px) {
                    right: -10px;
                    top: -11px;
                }
                @media (max-width: 480px) {
                    right: -5px;
                }
                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    text-align: center;
                    color: #fefefe;
                }
            }
        }

        .profile-dropdown {
            position: relative;
            cursor: pointer;
            .user-bg {
                background: #fefefe;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                height: 48px;
                padding: 12px 14px;
                border-radius: 100px;
                margin-left: 10px;
                @media (max-width: 768px) {
                    height: 32px;
                    padding: 9px;
                }
                @media (max-width: 480px) {
                    margin-left: 0;
                }
                .user-name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    color: var(--palette-secondary-700);
                    @media (max-width: 768px) {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 13px;
                    }
                    @media (max-width: 480px) {
                        display: none;
                    }
                }
                &:hover {
                    background: var(--palette-light-violet-700);
                    fill: var(--palette-secondary-200);
                    stroke: var(--palette-secondary-200);
                    color: var(--palette-secondary-200);
                    .user-name {
                        color: #fefefe;
                    }
                }
                div {
                    display: flex;
                    align-items: center;
                    .user-avatar {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        @media (max-width: 768px) {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }

                .status {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #28c76f;
                    position: absolute;
                    bottom: 0;
                    right: 45px;
                    @media (max-width: 768px) {
                        bottom: 14px;
                        right: 36px;
                    }
                    @media (max-width: 480px) {
                        right: 37px;
                    }
                }
            }
        }
    }
}
