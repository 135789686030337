.signIn-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 50px 20px;
    position: absolute;
    height: 100%;
    top: 0;
    left: -100%;
    transition: 0.8s left ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--palette-light-violet-200);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--palette-light-violet-500);
        opacity: 1;
        border-radius: 10px;
    }
    .arrow-back {
        position: absolute;
        top: 30px;
        left: 30px;

        @media (max-width: 768px) {
            left: 20px;
        }
    }
    .mobile_info {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        align-items: center;
        width: 100%;
        display: flex;
        p {
            display: none;
        }

        @media (max-width: 768px) {
            p {
                display: block;
            }
        }
        span {
            cursor: pointer;
            color: var(--palette-dark-violet-300);
        }
    }
    .signIn {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 420px;
        margin: 0 auto;

        &.loading {
            user-select: none;
            pointer-events: none;
            opacity: 0.7;
        }
        .button {
            margin-top: 10px;
            width: 100%;
        }
        .submitTerms {
            text-align: center;

            a {
                color: var(--palette-dark-violet-300);
            }
        }
        .checkbox-container {
            position: relative;
            input {
                width: 22px;
                height: 22px;
                cursor: pointer;
                position: absolute;
                opacity: 0;
                top: 5px;
                left: 5px;
            }
        }
    }
    .remember-forgot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .remember_me {
            display: flex;
            gap: 5px;
            align-items: center;

            input {
                width: 20px;
                height: 20px;
            }

            span {
                font-size: 14px;
            }
        }
        .forgot_password {
            font-size: 14px;
            color: var(--palette-dark-violet-300);
            cursor: pointer;
        }
    }
}
