.add-work {
    h2 {
        margin-bottom: 30px;
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
    h4 {
        font-weight: 500;
    }
    &_template {
        background-color: white;
        border-radius: 10px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media (max-width: 768px) {
            padding: 20px;
            gap: 20px;
        }
        .filters {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }

            .category {
                display: flex;
                flex-direction: column;
                gap: 10px;

                & > span {
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--palette-secondary-500);
                }
            }
        }
        .description {
            display: flex;
            flex-direction: column;

            h4 {
                margin-bottom: 10px;
            }
            textarea {
                width: 100%;
                height: 270px;
                resize: none;
                outline: none;
                background-color: transparent;
                padding: 9px 19px;
                border: 1px solid var(--palette-light-violet-700);
                border-radius: 10px;

                @media (max-width: 950px) {
                    height: 120px;
                }
            }
            & > span {
                text-align: end;
                margin-top: 5px;
                font-size: 12px;
                color: var(--palette-secondary-500);
            }
        }
        .photos {
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > span {
                font-size: 14px;
                line-height: 14px;
                color: var(--palette-secondary-500);
            }
        }
    }
    .buttons {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        width: 100%;
        justify-content: flex-end;
        padding-bottom: 60px;
    }
}
