.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(4, 0, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    box-shadow: -2px 0px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-end;

    .modal__content {
        width: 55vw;
        height: 100vh;
        border-radius: 12px 0 0 0;
        background-color: #f6f2fa;
        transform: scale(0.5);
        transition: 0.4s all;
        @media (max-width: 1440px) {
            width: 782px;
            min-height: 100vh;
        }
        @media (max-width: 1024px) {
            @media (max-width: 740px) {
                width: 100vw;
            }
        }
    }
}
.modal.active {
    opacity: 1;
    pointer-events: all;

    .modal__content.active {
        box-shadow: -2px 0px 20px rgb(0 0 0 / 10%);
        display: flex;
        transform: scale(1);
        overflow-y: scroll;

        @media (max-width: 680px) {
            display: block;
        }

        .modal__content-left {
            width: 41%;
            border-right: 1px solid #e3d8f3;

            @media (max-width: 680px) {
                width: 100%;
            }

            &__img {
                position: absolute;
                top: 32px;
                left: 32px;
                cursor: pointer;
            }

            &__up {
                width: 100%;
                min-width: 317px;
                padding: 35px;
                height: 327px;
                border-bottom: 1px solid #e3d8f3;

                @media (max-width: 740px) {
                    min-width: 275px;
                    h3 {
                        font-size: 18px;
                    }
                }

                @media (max-width: 680px) {
                    padding: 20px;
                }

                @media (max-width: 680px) {
                    border-bottom: none;
                }
            }

            &__down {
                padding: 30px 35px 35px 35px;
                @media (max-width: 1024px) {
                    h4 {
                        font-size: 14px;
                    }
                }

                @media (max-width: 680px) {
                    padding: 20px;
                }

                @media (max-width: 680px) {
                    padding: 0 35px 35px 35px;
                    border-bottom: 1px solid #e3d8f3;
                }
            }
        }

        .modal__content-right {
            width: 100%;
        }
    }
}
