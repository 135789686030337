.my-review-wrapper {
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 20px;
        }

        .tooltiptext {
            width: 280px;
            white-space: pre-wrap;
            line-height: 19.1px;
            text-align: start;
            font-size: 14px;
            font-weight: 400;
            left: 120%;
        }
    }

    .review-cards {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
