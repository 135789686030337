.add-treatment {
    padding-bottom: 20px;
    h2 {
        margin-bottom: 30px;
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
    h4 {
        font-weight: 500;
    }
    &_template {
        background-color: white;
        border-radius: 10px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media (max-width: 768px) {
            padding: 20px;
            gap: 20px;
        }
        .filters {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            @media (max-width: 768px) {
                display: block;
            }

            .or {
                padding-top: 17px;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }

            .category {
                width: 46%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                &.disabled {
                    .dropDown {
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }
                @media (max-width: 768px) {
                    width: 100%;
                }

                & > span {
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--palette-secondary-500);
                }
            }
        }

        .sub-filters {
            display: flex;
            align-items: center;
            gap: 4%;
            @media (max-width: 450px) {
                display: block;
            }
            .sub-category {
                width: 225px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                @media (max-width: 450px) {
                    width: 100%;
                    margin-top: 10px;
                }

                .price-input-container {
                    .input-primary {
                        padding: 9px 19px 9px 70px;
                    }
                    .price-input {
                        position: absolute;
                        width: 51px;
                        height: 44.5px;
                        background-color: #9e77d3;
                        border-radius: 10px 0 0 10px;
                        &__icon {
                            height: inherit;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .description {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            h4 {
                margin-bottom: 10px;
            }
            textarea {
                width: 100%;
                height: 270px;
                resize: none;
                outline: none;
                background-color: transparent;
                padding: 9px 19px;
                border: 1px solid var(--palette-light-violet-700);
                border-radius: 10px;
                &.error {
                    border-color: red;
                    &::placeholder {
                        color: red;
                    }
                }
                @media (max-width: 950px) {
                    height: 120px;
                }
            }
            & > span {
                text-align: end;
                margin-top: 5px;
                font-size: 12px;
                color: var(--palette-secondary-500);
            }
        }
        .photos {
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > span {
                font-size: 14px;
                line-height: 14px;
                color: var(--palette-secondary-500);
            }
        }
        .promo-switch {
            display: flex;
            gap: 15px;
            @media (max-width: 400px) {
                span {
                    font-size: 14px;
                }
            }
        }
    }

    .promo-inputs {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 900px) {
            //gap: 50px;
        }
        @media (max-width: 767px) {
            gap: 20px;
            display: flex;
            margin-right: 400px;
            flex-wrap: wrap;
        }

        @media (max-width: 500px) {
            margin-right: auto;
        }
        .tooltip {
            display: flex;
            gap: 10px;
        }
        .tooltiptext {
            width: 280px;
            white-space: pre-wrap;
            line-height: 19.1px;
            text-align: start;
            font-size: 14px;

            @media (max-width: 420px) {
                width: 180px;
            }
        }
        .fixed-review {
            position: fixed;
            top: 0;
            z-index: 200;
            overflow-y: hidden;
            padding-top: 20px;
            width: inherit;
            max-width: inherit;
            background: var(--palette-secondary-main-bg);
            @media (max-width: 768px) {
                margin-top: 125px;
            }
        }
        .promo-inputs__label {
            padding: 10px 0;
        }
        .price-input-container {
            .input-primary {
                padding: 9px 19px 9px 70px;
            }
            .price-input {
                position: absolute;
                width: 51px;
                height: 44.5px;
                background-color: #9e77d3;
                border-radius: 10px 0 0 10px;
                &__icon {
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .promo-inputs__left {
            display: flex;
            align-items: flex-end;
            .line {
                padding: 0 10px;
                padding-bottom: 2%;
            }
            .promo-percent {
                height: 44.5px;
                background-color: #9e77d3;
                border-radius: 8px;
                padding: 8px;
                color: #fefefe;
            }
        }

        .promo-inputs__rigth {
            display: flex;
            gap: 20px;
            justify-content: flex-start;
            align-items: flex-end;
            .line {
                padding: 0 10px;
                padding-bottom: 1.5%;
                @media (max-width: 440px) {
                    display: none;
                }
            }
            @media (max-width: 440px) {
                display: block;
            }
        }
    }

    .buttons {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        width: 100%;
        margin-bottom: 40px;
        justify-content: flex-end;
        padding-bottom: 40px;
    }
}
