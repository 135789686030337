.blocked-time {
    min-width: 650px;
    @media (max-width: 820px) {
        min-width: 80vw;
    }
    .blocked-time_days {
        display: flex;
        padding: 20px 0;
        align-items: center;
        gap: 10px 20px;
        .blocked-time_date {
            width: 100%;
        }
        @media (max-width: 440px) {
            flex-direction: column;
        }
    }
    .one-day-switch {
        display: flex;
        gap: 10px;
        user-select: none;
        align-items: center;
        padding: 0 0 20px 0;
        p {
            font-weight: 500;
            font-size: 16px;
        }
    }
    .blocked-time_time {
        display: flex;
        align-items: center;
        gap: 10px 20px;
        padding-bottom: 15px;
    }
    .block-all-day {
        user-select: none;
        display: flex;
        align-items: center;
        p {
            font-size: 14px;
        }
        .checkbox {
            display: flex;
            align-items: center;
            margin-right: 5px;
        }
    }
    .blocked-time_description {
        padding: 20px 0;
        .description_substring {
            color: var(--palette-secondary-500);
            font-size: 14px;
            padding-bottom: 6px;
        }
        textarea {
            border-radius: 10px;
            border: 1px solid var(--palette-light-violet-700);
            padding: 20px;
            width: 100% !important;
            min-height: 115px;
            background: transparent;
        }
    }
    .blocked-time-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    }
}
