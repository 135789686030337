.reply-card {
    position: relative;
    width: 100%;
    background: #fefefe;
    border-radius: 8px;
    margin-bottom: 50px;
    border-right: 10px solid var(--palette-light-violet-300);
    @media (max-width: 768px) {
        margin-bottom: 20px;
        &_line {
            display: none;
        }
    }
    &.client-reply {
        border-right: none;
        border-left: 10px solid var(--palette-dark-violet-100);
    }
    &_line {
        position: absolute;
        left: 20px;
        top: -50px;
        width: 1px;
        height: 50px;
        background: var(--palette-light-violet-700);
    }
    .reply-padding {
        padding: 20px 20px 20px 20px;
        border-top: 1px solid var(--palette-light-violet-200);
    }
    .my-reply-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        color: var(--palette-secondary-500);
        padding-left: 10px;
        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 20px;
        }
    }
    .reply-title {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px 20px 20px 10px;
        .reply-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 27px;
            @media (max-width: 768px) {
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    .reply-text {
        white-space: pre-wrap;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        @media (max-width: 768px) {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
        }
    }

    .reply-footer {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: var(--palette-secondary-500);
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
            }
        }
        .status {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: var(--palette-secondary-500);
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.09em;
            }
        }
    }
}

//.side-style {
//    width: 10px;
//    background: #7c46c4;
//    border-radius: 8px 0px 0px 8px;
//    height: 100%;
//}
//
//.side-style-right {
//    width: 10px;
//    background: #c8b1e6;
//    border-radius: 0px 8px 8px 0px;
//    margin-bottom: 20px;
//}
