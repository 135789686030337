.room-business-info-wrapper {
    .business-info-room {
        background: #fefefe;
        border-radius: 10px;
        padding: 35px;
        @media (max-width: 360px) {
            padding: 20px;
        }

        h4 {
            color: var(--palette-secondary-600);
            font-weight: 500;
            margin-left: 1px;
            margin-bottom: 6px;
            @media (max-width: 768px) {
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
            }
        }

        &__grid-view {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-bottom: 30px;
            @media (max-width: 768px) {
                grid-template-columns: 100%;
            }

            .input-group-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                gap: 7px;

                .input-group {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    outline: none;
                    background-color: transparent;
                    padding: 9px 19px;
                    border: 1px solid var(--palette-light-violet-700);
                    border-radius: 10px;
                    width: 100%;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    cursor: pointer;
                    gap: 5px;

                    .choose-button {
                        display: inherit;
                        border: none;
                        background: none;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 14px;
                        letter-spacing: 0.12em;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        color: var(--palette-dark-violet-300);
                        cursor: pointer;
                        margin-left: 10px;
                    }
                    @media (max-width: 360px) {
                        width: 93% !important;
                    }

                    &::placeholder {
                        color: #938f8c;
                    }

                    &:hover {
                        border-color: var(--palette-dark-violet-300);
                    }

                    &:focus {
                        border-color: var(--palette-dark-violet-500);
                    }

                    input {
                        flex-grow: 2;
                        border: none;
                        cursor: pointer;
                        font-size: 12px;
                    }

                    input:focus {
                        outline: none;
                    }
                }
            }
        }

        &__amenities {
            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                @media (max-width: 480px) {
                    font-size: 14px;
                }
            }

            .amenities-grid {
                margin: 15px 0 35px 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 12px;
                @media (max-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }
                @media (max-width: 600px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }

    .delete-account {
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: var(--palette-dark-violet-300);
        border: none;
        background: none;
        margin: 30px 0;
    }
}
