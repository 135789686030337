.sales-by-service {
  width: 100%;
  &-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
  .circle-box {
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 275px;
    @media (max-width: 768px) {
      height: 150px;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      b, p{
        font-size: 14px;
        color: #31394D;
      }
      .info{
        display: flex;
        align-items: center;
        gap: 20px;
        .item-circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 4px solid;
        }
      }
    }
  }
}