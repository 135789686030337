.modalUpWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .nameImg {
        height: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 680px) {
            justify-content: space-evenly;
        }
        &__userPhoto {
            width: 100px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .buttons {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
}
