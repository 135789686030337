.locations-list-modal {
  width: 200px;
  max-height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  left: -80px;
  margin-top: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  .row {
    padding: 10px;
  }
  .row:hover {
    background: #E3D8F3;
    cursor: pointer;
  }

.input-primary-wrap {
  display: unset !important;

}


}
//.autocomplete-container {
//  width: calc(100% - 5px);
//}

.pac-container {
  z-index: 1050;
}

.input-primary {
  @media (max-width: 678px) {
    padding-right: 50px !important;
  }
}