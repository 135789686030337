.notification-dropdown-card{
  width: 100%;
  background: #FEFEFE;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-top: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--palette-light-violet-200);

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    .notification-mark {
      border-radius: 50%;
      background-color: var(--palette-dark-violet-300);
      width: 18px;
      height: 18px;
      position: relative;

      &.is_read {
        background-color: var(--palette-light-violet-200);
        pointer-events: none;
        user-select: none;
      }

      span {
        position: absolute;
        font-size: 10px;
        white-space: nowrap;
        right: -10px;
        top: -20px;
        color: var(--palette-dark-violet-300);
        background-color: var(--palette-light-violet-100);
        border-radius: 100px;
        padding: 1px 5px;
        border: 1px solid var(--palette-light-violet-300);
        transition: 0.3s all;
        opacity: 0;
        pointer-events: none;
      }

      &:hover span {
        opacity: 1;
      }
    }
  }
  &__content{
    padding: 10px 20px;

    p{
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    span{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--palette-secondary-500);
    }
  }

}