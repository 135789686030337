.dropDown {
    outline: none;
    background-color: transparent;
    padding: 9px 19px;
    position: relative;
    border: 1px solid var(--palette-light-violet-700);
    border-radius: 10px;
    width: 100%;
    z-index: 2;
    span {
        color: var(--palette-secondary-600);

        font-size: 18px;

        &.all-option {
            color: var(--palette-secondary-500);
        }
    }
    .selected {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
            transition: 0.2s all;
            &.rotate {
                transform: rotate(180deg);
            }
        }
    }
    .options {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: white;
        overflow: auto;
        pointer-events: none;
        max-height: 0px;
        cursor: pointer;
        transition: 0.3s all;

        &.opened {
            pointer-events: all;
            max-height: 50vh;
            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--palette-light-violet-200);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--palette-light-violet-500);
            opacity: 1;
            border-radius: 10px;
        }

        span {
            width: 100%;
            padding: 10px 20px;
            transition: 0.2s all;
            &:not(:last-child) {
                border-bottom: 1px solid var(--palette-light-violet-400);
            }
            &.active_option {
                background-color: var(--palette-light-violet-200);
            }

            &:hover {
                background-color: var(--palette-light-violet-300);
            }
        }
    }
}
