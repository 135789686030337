.publish-confirmation {
  //width: 645px;
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    padding-right: 20px;
    @media (max-width: 360px) {
      font-size: 28px;
      line-height: 32px;
  }
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--palette-secondary-500);
  margin: 10px 0 20px 0;
  word-wrap: normal;
}

.publish-action {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  @media (max-width: 340px) {
    flex-direction: column;
  }
}

}