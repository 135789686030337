.onboarding-header {
    background: var(--palette-secondary-600);
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 500;
    .onboarding-header-logo {
        padding: 10px 0;
    }
}
.onboarding-process {
    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        margin: 30px 0;
    }
    .onboarding-process-form {
        background: #fefefe;
        border-radius: 10px;
        padding: 35px;
        margin-bottom: 30px;
        h4 {
            color: var(--palette-secondary-600);
            font-weight: 500;
            margin-left: 1px;
            margin-bottom: 6px;
            @media (max-width: 768px) {
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
            }
        }
        .form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-bottom: 30px;
            @media (max-width: 768px) {
                grid-template-columns: 100%;
            }

            .input-group-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                gap: 7px;

                .input-group {
                    display: flex;
                    flex-direction: row;
                    outline: none;
                    background-color: transparent;
                    padding: 9px 19px;
                    border: 1px solid var(--palette-light-violet-700);
                    border-radius: 10px;
                    width: 100%;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    @media (max-width: 360px) {
                        width: 93% !important;
                    }
                    &::placeholder {
                        color: #938f8c;
                    }
                    &:hover {
                        border-color: var(--palette-dark-violet-300);
                    }
                    &:focus {
                        border-color: var(--palette-dark-violet-500);
                    }
                    input {
                        flex-grow: 2;
                        border: none;
                    }
                    input:focus {
                        outline: none;
                    }

                    button {
                        border: none;
                        background: none;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 14px;
                        letter-spacing: 0.12em;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        color: var(--palette-dark-violet-300);
                        cursor: pointer;
                    }
                }
            }
            &__textarea {
                width: 100%;
                margin-bottom: 30px;
                h4 {
                    margin-bottom: 0 !important;
                }
                span {
                    font-family: 'Nunito Sans', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: var(--palette-secondary-500);
                }
                textarea {
                    margin-top: 10px;
                    color: var(--palette-secondary-600);
                    width: 100%;
                    height: 190px;
                    resize: none;
                    outline: none;
                    background-color: transparent;
                    padding: 9px 19px;
                    border: 1px solid var(--palette-light-violet-700);
                    border-radius: 10px;

                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    @media (max-width: 750px) {
                        font-size: 0.8rem;
                    }
                    @media (max-width: 550px) {
                        height: 120px;
                    }
                    &:hover {
                        border-color: var(--palette-dark-violet-300);
                    }
                    &:focus {
                        border-color: var(--palette-dark-violet-500);
                    }
                    &::placeholder {
                        color: #938f8c;
                    }
                }
                .message_textArea__error {
                    border-color: #ff2200;
                    &::placeholder {
                        color: #ff2200;
                    }
                    &:hover {
                        border-color: #ff2200 !important;
                    }
                    &:focus {
                        border-color: #ff2200 !important;
                    }
                }
            }
        }
    }
}
