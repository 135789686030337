.formLoader {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 15px;
  top: 0;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: center;

  &.relative {
    position: inherit;
    .formLoading {
      margin-left: 0;
    }
  }
}

@keyframes preload {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

.formLoader .formLoading {
  z-index: 999999;
  display: block;
  height: 14px;
  margin: 0 auto;
  padding: 0;
}

.formLoader .formLoading li {
  background: #fff;
  opacity: 0.5;
  display: block;
  float: left;
  width: 12px;
  height: 12px;
  line-height: 12px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  animation: preload 1s infinite;
  border-radius: 50%;
}
.formLoader_purple .formLoading li {
  background: var(--palette-dark-violet-400) !important;
}

.formLoader .formLoading li:first-child {
  margin-left: 0
}

.formLoader .formLoading li:nth-child(2) {
  animation-delay: .15s
}

.formLoader .formLoading li:nth-child(3) {
  animation-delay: .3s
}

.formLoader.formLoader-complete {
  opacity: 0;
  visibility: hidden;
  transition-duration: 1s
}