.card_slider {
    width: 100%;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    aspect-ratio: 3/2;
    .slick-track {
        gap: 0;
    }
    .slick-dots {
        bottom: 16px !important;

        li {
            width: 10px;
            height: 10px;
            margin: 0 3px;
        }

        button {
            width: 10px;
            height: 10px;
            padding: 0px;
            border-radius: 50%;
            border: 1px solid var(--palette-light-violet-100);
            color: transparent;
            background-color: var(--palette-dark-violet-300);
            opacity: 1;
            transition: 0.3s all;
        }
        button::before {
            content: none;
            font-size: 0;
            display: none;
        }
        .slick-active button {
            background-color: var(--palette-light-violet-100);
            border: 1px solid var(--palette-dark-violet-300);

            opacity: 1;
        }
    }
    .custom-arrow {
        width: 32px;
        height: 32px;
        transition: 0.2s all;
        opacity: 0;
        background: #fbf9fb;
        box-shadow: 2px 2px 20px rgb(144 99 205 / 50%);
        border-radius: 50%;
        position: absolute !important;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            width: inherit;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover {
            background: #582e90;
            box-shadow: 2px 2px 20px rgba(144, 99, 205, 0.5);
            cursor: pointer;
        }
    }
    &:hover .custom-arrow {
        opacity: 1;
    }
    .custom-arrow-next {
        right: 10px;
        top: 48%;
    }
    .custom-arrow-prev {
        z-index: 1;
        left: 10px;
        top: 48%;
    }
}
