.day_time {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  @media (max-width: 768px) {
    gap: 10px;
  }
  .minus-icon {

    @media (max-width: 540px) {
      img {
        width: 100%;
      }
      width: 16px !important;
      height: 16px !important;
    }
  }
}