.not-found {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 50px;

    &__img {
        width: 95%;
        max-width: 400px;
        img {
            width: 100%;
        }
    }

    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        color: var(--palette-dark-violet-700);
        @media (max-width: 740px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: var(--palette-dark-violet-700);
        @media (max-width: 740px) {
            font-size: 12px;
            line-height: 16px;
        }
    }
}
