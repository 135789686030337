.no-location-modal {
    width: 100%;
    max-width: 800px !important;
    * {
        user-select: none;
    }
    .no-location-title {
        display: flex;
        justify-content: center;
    }
.text-h2 {
    margin-bottom: 20px;
}


    .current-address {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--palette-secondary-500);
        margin-top: 10px;
    }
    .show-map-container {
        margin-top: 25px;
    }

    .show-map-btn {
        font-size: 10px;
        color: var(--palette-dark-violet-300);
        text-decoration: underline;
        font-weight: 600;
    }

    .modal_title {
        font-family: 'Comfortaa',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #12091d;
    }
    .primary-button {
        margin-right: 20px;
    }
    .log-out-btn {
        background: none;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #292929;

        &:hover {
            color: #fefefe;
        }
    }
}

.modal-icon-close-location {
    .close {
        top: 20px !important;
        right: 20px !important;
    }

    @media (max-width: 768px) {
        .close {
            top: 10px !important;
            right: 10px !important;
        }

    }
    @media (max-width: 550px) {
        .close {
            top: 5px !important;
            right: 5px !important;
        }

    }
}