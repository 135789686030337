.DropdownAction {
    position: relative;
    overflow: unset !important;
    .action-dropdown-container {
        position: absolute;
        z-index: 3;
        background: #fefefe;
        width: 169px;
        overflow-x: visible !important;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        top: 30px;
        right: 0;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        .dropDown-btn {
            cursor: pointer;
            padding: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            &:hover {
                background: var(--palette-light-violet-700);
                color: #fefefe;
            }
        }
    }
}
