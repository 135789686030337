.calendar-event {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    user-select: none;
    //border-bottom: 1px solid white;
    width: 100%;
    height: 100%;
    padding: 6px 2px 2px 6px;

    &.event-blocked-time {
        background: var(--palette-secondary-300);
        .left-border {
            background: var(--palette-secondary-500) !important;
        }
    }
    .left-border {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background: var(--palette-dark-violet-300);
    }
    .event-time {
        font-size: 12px;
        font-weight: 700;
    }
    .event-client {
        color: var(--palette-dark-violet-300);

        font-size: 12px;
    }
    .event-room,
    .event-description {
        font-size: 12px;
        color: #3a393a;
    }
}

.rbc-time-view {
    .calendar-event,
    .calendar-day-event {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
}
.event-treatments {
    font-size: 10px;
}

.calendar-day-event {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 6px;
    padding: 10px;

    .event-time {
        font-size: 18px;
        font-weight: 700;
    }
    .event-client {
        font-size: 16px;
    }
    .event-room {
        font-size: 16px;
        color: #3a393a;
    }
}
.event-tooltip {
    background: white !important;
    border-radius: 10px !important;
    min-width: 400px;
    z-index: 1000 !important;
    padding: 0 !important;
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
    @media (max-width: 768px) {
        display: none !important;
    }
    .tooltip_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid var(--palette-light-violet-200);
        .clientsPhoto {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
        }
        .client-profile {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .tooltip-badge {
            background-color: #28c76f;
            padding: 3px 9px;
            border-radius: 17px;
            color: #fff;
            font-size: 12px;
            border-style: none;
            text-transform: capitalize;
            &.canceled {
                background-color: #ee3114 !important;
            }

            &.declined {
                background-color: #f26022 !important;
            }

            &.completed {
                background-color: #ac8ada !important;
            }
        }
    }

    .tooltip-info_container {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        border-bottom: 1px solid var(--palette-light-violet-200);
        .tooltip-info {
            display: flex;
            align-items: center;
            gap: 5px;
            p {
                color: var(--palette-dark-violet-300);
                font-size: 14px;
            }
        }
    }
    .tooltip-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        p {
            font-weight: 600;
        }
    }
}
