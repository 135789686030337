.from_to {
  width: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  &_datepicker {
    user-select: none;
  }
  .custom-date-btn {
    display: flex;
    span {
      user-select: none;
      font-size: 12px;
      color: var(--palette-secondary-500);
      padding-right: 10px;
    }
    &.from {
      margin-right: 20px;
    }
    .example-custom-input {
      font-size: 12px;
      font-weight: 700;
      border: none;
      background-color: transparent;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: var(--palette-dark-violet-200);
  }
}