.workingHoursDay {
    padding: 20px 35px;
    border-bottom: 1px solid #e9eaec;
    @media (max-width: 768px) {
      padding: 10px 20px;

    }
    .day_container {
        display: flex;
        align-items: flex-start;
        @media (max-width: 540px) {
            flex-direction: column;
        }
        .label{
            color: var(--palette-secondary-500);
            user-select: none;
            font-size: 14px;
            padding-bottom: 30px;
            @media (max-width: 768px) {
                padding-bottom: 20px;
            }
            @media (max-width: 540px) {
               display: none;
            }
        }
        .day_name {
            width: 200px;
            height: 45px;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            @media (max-width: 768px) {
                width: 160px;
            }
            .weekday_name {
                text-transform: capitalize;
                @media (max-width: 768px) {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .day_schedule {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media (max-width: 768px) {
               gap: 10px;
            }
        }
    }
}
