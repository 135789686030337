.sales-summary {
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  &-chart-box {
    overflow: auto;

      &::-webkit-scrollbar {
        border-radius: 100px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #e3e3e3;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--palette-light-violet-500);
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: var(--palette-dark-violet-100);
      }

    .summary-chart {
      height: 400px;
      min-width: 520px;
    }
  }
}