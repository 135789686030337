.onboarding-wrapper {
    min-height: calc(100vh - 150px);

    .rdt_Table {
        border-radius: 10px 10px 0 0;
        width: 100%;
        .insurance-date {
            font-size: 10px;
            font-weight: 400;
            color: var(--palette-secondary-500);
        }
    }

    .data-table-border {
        border-radius: 10px 10px 0 0;
        border: 1px solid #e0e7ed;
        @media (max-width: 768px) {
            display: none;
        }
        .embYUS,
        div[data-tag='allowRowEvents'] {
            overflow: visible !important;
        }
        .tick {
            cursor: auto;
        }

        .rdt_TableHeadRow {
            border-radius: 10px 10px 0 0;
        }
    }
    .accordion-mobile {
        width: 100%;
        flex-direction: column;
        display: none;
        @media (max-width: 768px) {
            display: flex;
        }
    }
    .data-table-wrapper {
        background: #fefefe;
        padding: 35px;
        border: 1px solid transparent;
        border-radius: 10px 10px 0 0;
        @media (max-width: 768px) {
            border: none;
            border-radius: 0;
            padding: 0;
            background: transparent;
        }
        .bMgaAx div:first-child {
            overflow: visible;
        }

        .gelpCx {
            // margin-bottom: 33px;
            overflow: visible;
        }

        .rdt_TableHeadRow {
            background-color: #f9f9f9 !important;

            .rdt_TableCol {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.12em;
                text-transform: uppercase;
                color: var(--palette-secondary-500);
            }
        }

        .paginationDiv {
            min-height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__text {
                font-size: 12px;
                @media (max-width: 500px) {
                    display: none;
                }
            }

            .ant-pagination {
                width: 50%;
                justify-content: end;
                padding-right: 3%;
                @media (max-width: 500px) {
                    width: 100%;
                    padding-right: 0;
                    justify-content: center;
                }
            }
        }

        .rdt_TableRow:hover {
            background-color: #f1ecf9;
            border: 1px solid #e0e7ed;
        }
    }

    .action-btn {
        border: none;
        background: none;
        text-align: center;
        padding: 0 10px;
    }

    .add-docs {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .add-documents-btn {
            margin-bottom: 35px;
        }
    }
    .accordion-mobile {
    }
}
