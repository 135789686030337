.upload-doc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 7px;

    .explanation {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #848286;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    .upload-doc-label {
        color: var(--palette-secondary-600);
        font-weight: 500;
        margin-left: 1px;
    }

    .doc-upload {
        display: flex;
        gap: 5px;
        cursor: pointer;
        outline: none;
        background-color: transparent;
        padding: 9px 19px;
        border: 1px solid var(--palette-light-violet-700);
        border-radius: 10px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .upload-txt {
            flex-shrink: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            text-align: right;
            letter-spacing: 0.12em;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: #582e90;
        }
        .upload-txt-none {
            display: none;
        }

        .upload-placeholder {
            overflow: hidden;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #848286;
            @media (max-width: 360px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
    .input-primary-error {
        border-color: #ff2200;
        &::placeholder {
            color: #ff2200;
        }
        &:hover {
            border-color: #ff2200 !important;
        }
        &:focus {
            border-color: #ff2200 !important;
        }
    }
    .error-msg {
        position: absolute;
        color: #ff2200;
        left: 0;
        bottom: -18px;
        font-size: 0.6rem;
    }
}
