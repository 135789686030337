.noClients__Wrapper {
    min-height: calc(100vh - 150px);
    .noClientsDiv {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 24px;
            font-weight: 600;
        }
        img {
            @media (max-width: 1024px) {
                width: 449px;
            }
            @media (max-width: 760px) {
                width: 368px;
            }
            @media (max-width: 360px) {
                width: 224px;
            }
        }
    }
}
