.chat-container {
    .chat-wrapper {
        filter: drop-shadow(2px 2px 10px rgba(181, 149, 225, 0.2));
        width: 360px;
        height: auto;
        background: white;
        position: fixed;
        bottom: 130px;
        right: 40px;
        z-index: 900;
        border-radius: 14px 14px 0 14px;
        @media screen and (max-width: 750px) {
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        .header {
            height: 55px;
            width: 100%;
            padding: 16.5px 10px 16.5px 20px;
            display: flex;
            background: #ac8ada;
            justify-content: space-between;
            border-radius: 14px 14px 0 0;
            .close-button {
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .body {
            //height: 383px;
            width: 100%;
            background: #fff;
            padding: 20px 20px;
            overflow-y: scroll;
            height: 356px;
            //overflow: auto;
            display: flex;
            flex-direction: column;
            //justify-content: flex-end;
            @media screen and (max-width: 750px) {
                height: calc(100% - 127px);
            }

            .loader {
                min-height: 100% !important;
            }

            .message-block {
                min-width: min-content;
                border-radius: 10px;
                display: flex;
                margin-bottom: 20px;
                max-width: 100%;
                // my messages
                &.right {
                    justify-content: flex-end;
                    .avatar-user {
                        display: none;
                    }
                    .message {
                        background: #7c46c4;
                        border-radius: 10px 0 10px 10px;
                        .message-text {
                            color: #fefefe;
                            word-break: break-word;
                        }
                        .time {
                            color: #fefefe;
                        }
                        .username {
                            display: none;
                        }
                    }
                }

                // other messages
                .avatar-user {
                    width: 40px;
                    height: 40px;
                    border: 1px solid #fefefe;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .message {
                    background: #f1ecf9;
                    border-radius: 0 10px 10px 10px;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    .username {
                        font-family: 'Nunito Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #582e90;
                    }
                    .message-text {
                        font-family: 'Nunito Sans', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        color: #3a393a;
                        word-break: break-word;
                    }
                    .time {
                        font-family: 'Nunito Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 14px;
                        color: #848286;
                        text-align: end;
                    }
                }
            }
            .empty-chat {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                div {
                    display: flex;
                    flex-direction: column;
                    margin-top: 17px;
                }
                .empty-chat-title {
                    font-family: 'Comfortaa', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: #12091d;
                    text-align: center;
                }
                .empty-chat-description {
                    font-family: 'Nunito Sans', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #12091d;
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
        .footer {
            height: 72px;
            width: 100%;
            padding: 20px;
            border-top: 1px solid #d5c5ec;
            display: flex;
            align-items: center;
            justify-content: space-between;
            input {
                resize: none;
                border: none;
                font-family: 'Nunito Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                width: 85%;
                height: auto;
                max-height: 100px;
            }
            .send-button {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;
                gap: 8px;
                width: 32px;
                height: 32px;
                background: #582e90;
                border-radius: 50%;
                border: none;
            }
        }
    }

    .chat-button {
        width: 70px;
        height: 70px;
        background: #582e90;
        box-shadow: 2px 2px 10px rgba(88, 46, 144, 0.5);
        border-radius: 50%;
        position: fixed;
        bottom: 47px;
        right: 40px;
        z-index: 888;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
