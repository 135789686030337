.drop-zone {
    aspect-ratio: 6/2;
    margin-bottom: 40px;
    position: relative;
    @media (max-width: 630px) {
        aspect-ratio: 3/2;
    }
}

.slider-dropzone {
    position: relative;
    width: 100%;
    @media (max-width: 618px) {
        border: 1px dashed #292929;
        border-radius: 8px;
    }
    .slick-list {
        aspect-ratio: 6/2;
        @media (max-width: 630px) {
            aspect-ratio: 3/2;
        }

        .slick-active.slick-current {
            .img-uploader-zone {
                border-right: none;
                border-radius: 10px 0 0 10px;
            }
        }

        .slick-active {
            .img-uploader-zone {
                border-radius: 0 10px 10px 0;
            }
        }
    }

    .dropzone-container {
        width: 100%;
        position: relative;
        .preview-wrapper {
            .preview-img {
                aspect-ratio: 3/2;
                width: 100%;
                object-fit: cover;
            }

            .action-btn {
                position: absolute;
                bottom: 10px;
                width: 100%;
                gap: 30px;
                justify-content: center;
                display: none;
                @media (max-width: 630px) {
                    bottom: 35px;
                }

                button {
                    border: none;
                    background: none;
                }

                // .delete-btn {
                //     position: absolute;
                //     bottom: 10px;
                //     left: 183px;
                // }
                // .edit-btn {
                //     position: absolute;
                //     bottom: 10px;
                //     right: 183px;
                // }
            }

            &:hover {
                box-shadow: inset 0 0 0 1000px rgba(5, 1, 11, 0.56);
                background: rgba(5, 1, 11, 0.56);

                .action-btn {
                    display: flex;
                    z-index: 1;
                }

                & > img {
                    opacity: 0.3;
                }
            }
        }

        .img-uploader-zone {
            cursor: pointer;
            // height: 316px;
            // width: 475px;
            width: 100%;
            aspect-ratio: 3/2;
            border: 1px dashed #292929;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px;
            background: var(--palette-light-violet-100);
            @media (max-width: 618px) {
                border: none;
            }
            &:hover{
                background: var(--palette-light-violet-300);
                transition: .3s all;
                .plus-img{
                    border-radius: 50%;
                    background: var(--palette-light-violet-500);
                }
            }

            .inside-txt {
                @media (max-width: 768px) {
                    //max-width: 135px;
                }

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    line-height: 19px;
                    margin-bottom: 7px;
                    @media (max-width: 768px) {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 19px;
                    }
                }


                display: flex;
                align-items: center;
                gap: 0;
            }
            .tooltiptext {
                width: 250px;
                white-space: pre-wrap;
                top: -86px;
                left: -54px;
            }

            .tooltip .tooltiptext::after{
                bottom: -10px;
                top: auto;
                left: 190px;
                transform: rotate(-180deg);
                //-webkit-transform-origin: 5px 25px;
            }
        }
    }

}

.delete-photo-confirmation{
    h4{
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
    }

    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: var(--palette-secondary-500);
        margin: 10px 0 20px 0;
    }

    .delete-photo-action{
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        .primary-button_outlined {
            border-color: var(--palette-secondary-red);
            color: var(--palette-secondary-red);
            &:hover {
                color: #FEFEFE;
                background-color: var(--palette-secondary-red);
                border: 1px solid var(--palette-secondary-red);

            }
        }
    }

    @media (max-width: 783px) {
        width: 80vw;
    }
}