.doc-accordion-element {
    display: none;
    &.closed {
        .accordion-title {
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    @media (max-width: 768px) {
        display: block;
        width: 100%;
        background: #fefefe;
        margin-top: 20px;
        padding: 20px;

        .accordion-title {
            display: flex;
            gap: 5px;
            justify-content: space-between;
            align-items: flex-start;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
            }

            .icon {
                transition: 0.3s all;
            }

            .icon-rotate {
                transform: rotate(180deg);
            }
        }
        .accordion-content {
            opacity: 0;
            user-select: none;
            pointer-events: none;
            transition: height 0.2s;
            height: 0;

            .element {
                display: grid;
                grid-template-columns: 120px 1fr;
                align-items: center;
                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #bbbbbb;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3a393a;
                }
            }

            .icon-wrapper {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .accordion-action {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                @media (max-width: 322px) {
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                }
            }
        }
        .active-accordion {
            padding-top: 10px;

            transition: height 0.2s, opacity 0.3s 0.1s;
            opacity: 1;
            display: flex;
            user-select: auto;
            pointer-events: all;
            flex-direction: column;
            gap: 10px;
            height: auto;
        }
    }
    .insurance-date {
        font-size: 14px;
        font-weight: 400;
        color: var(--palette-secondary-600);
    }
}
