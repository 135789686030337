.rating-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    .stars-count {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: var(--palette-dark-violet-400);
        opacity: 0.5;
        height: 27px;
        line-height: 30px;
        @media (max-width: 768px) {
            margin-left: 0;
        }
    }
}
