.modalBookings {
  width: 100%;
  padding: 35px;

  @media (max-width: 800px) {
    padding: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    h1 {
      @media (max-width: 1440px) {
        font-size: 32px;
        font-weight: 600;
      }
      @media (max-width: 360px) {
        font-size: 20px;
      }
    }

    .filter {
      cursor: pointer;
      padding: 6px 20px;
      background: #582e90;
      border-radius: 10px;
      color: #fefefe;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      border: none;
    }

    .filter-content {
      width: 169px;
      border-radius: 8px;
      background-color: #ffffff;
      position: absolute;
      top: 40px;
      right: 17px;
      box-shadow: -2px 0px 20px rgb(0 0 0 / 10%);

      p {
        cursor: pointer;
        padding: 10px 20px;
        font-style: normal;

        &:hover {
          background-color: #9e77d3;
          color: #FEFEFE;
        }

        &:first-child {
          border-radius: 8px 8px 0 0;
        }

        &:last-child {
          border-radius: 0 0 8px 8px;
        }
      }
    }

  }

  .card:hover {
    border: 1px solid #582e90;
  }

  .card {
    cursor: pointer;
    border-radius: 8px;
    background-color: #fefefe;
    width: 100%;
    border: 1px solid transparent;
    transition: 0.2s;
    min-width: 395px;
    min-height: 202px;
    margin-bottom: 10px;

    @media (max-width: 720px) {
      width: 100%;
      min-width: 300px;
    }
    @media (max-width: 360px) {
      width: 315px;
      min-width: 90%;
      min-height: 296px;
    }

    .sectionImgName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #e3d8f3;

      div:nth-child(1) {
        display: flex;
        align-items: center;

        img {
          border-radius: 15px;
          width: 30px;
          margin-right: 10px;
        }
      }

      div:nth-child(2) {
        background-color: #28c76f;
        padding: 3px 9px;
        border-radius: 17px;
        color: #fff;
        font-size: 12px;
        border-style: none;
      }

      div:nth-child(2)::first-letter {
        text-transform: uppercase;
      }

      .canceled {
        background-color: #ee3114 !important;
      }

      .declined {
        background-color: #f26022 !important;
      }

      .completed {
        background-color: #ac8ada !important;
      }
    }

    .sectionInfo {
      padding: 12px 20px;
      display: flex;
      border-bottom: 1px solid #e3d8f3;

      > div {
        width: 50%;

        div {
          display: flex;
          align-items: center;
          color: #582e90;
          font-size: 12px;
          padding: 4px 0;

          img {
            padding-right: 10px;
          }
        }
      }
    }

    .sectionPrice {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 600;

      p {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .background404 {
    width: 100%;
    padding: 26px 0;
    background-color: #fbf9fb;
    text-align: center;
    border-radius: 8px;

    img {
      @media (max-width: 1440px) {
        width: 70%;
      }
    }

    p {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      @media (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
}
