.basic-info {
    background: #fefefe;
    border-radius: 10px;
    padding: 35px;
    @media (max-width: 360px) {
        padding: 20px;
    }

    h4 {
        color: var(--palette-secondary-600);
        font-weight: 500;
        margin-left: 1px;
        margin-bottom: 6px;
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
        }
    }

    &__grid-view {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
            grid-template-columns: 100%;
        }

        .basic-info-input {
            @media (max-width: 360px) {
                width: 100% !important;
            }
        }
    }

    .dropDown {
        width: 100%;
        background-position: 20px 50%;
        background-repeat: no-repeat;
        height: 46px;
        opacity: unset !important;
        margin-top: 12px;
    }

    .input-group-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        gap: 7px;

        .input-group {
            display: flex;
            flex-direction: row;
            outline: none;
            background-color: transparent;
            padding: 9px 19px;
            border: 1px solid var(--palette-light-violet-700);
            border-radius: 10px;
            width: 100%;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            cursor: pointer;
            gap: 5px;
            @media (max-width: 360px) {
                width: 93% !important;
            }

            &::placeholder {
                color: #938f8c;
            }

            &:hover {
                border-color: var(--palette-dark-violet-300);
            }

            &:focus {
                border-color: var(--palette-dark-violet-500);
            }

            input {
                flex-grow: 2;
                border: none;
                cursor: pointer;
                font-size: 12px;
            }

            input:focus {
                outline: none;
            }

            button {
                border: none;
                background: none;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.12em;
                text-decoration-line: underline;
                text-transform: uppercase;
                color: var(--palette-dark-violet-300);
                cursor: pointer;
            }
        }
    }

    &__textarea {
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .language-field {
        .multiselect {
            margin-top: 12px;

            .search-wrapper {
                outline: none;
                background-color: transparent;
                padding: 7px 19px;
                border: 1px solid var(--palette-light-violet-700);
                border-radius: 10px;

                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;

                &::placeholder {
                    color: #938f8c;
                }
                //.searchBox {
                //    width: 0;
                //}
            }

            .optionContainer {
                display: block;
                padding: 0;
                margin: 0;
                max-height: 250px;
                overflow-y: auto;
                border-radius: 8px;
                background: #fefefe;
            }

            .optionContainer li:hover,
            .optionContainer li:focus,
            .optionContainer li:active {
                background: var(--palette-light-violet-700);
                color: #fff;
            }

            .highlightOption {
                background: var(--palette-light-violet-700);
                color: #fff;
            }

            .highlight {
                background: var(--palette-light-violet-700);
                color: #fff;
            }

            .chip {
                background: #e3d8f3;
                color: #292929;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.12em;
            }

            .option:hover {
                background: var(--palette-light-violet-700) !important;
            }

            .chip {
                text-transform: uppercase;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

.delete-account {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--palette-dark-violet-300);
    border: none;
    background: none;
    margin: 30px 0;
    &:hover {
        color: var(--palette-dark-violet-100);
    }
}

.photo-zone {
    position: relative;
    display: block;

    .userProfile_photo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 100px;
        border: 1px solid var(--palette-dark-violet-300);
        object-fit: fill;
        background-color: white;
        position: absolute;
        cursor: pointer;
        bottom: -12px;
        left: 110px;
        @media (max-width: 1500px) {
            bottom: -8px;
        }
        @media (max-width: 780px) {
            width: 66px;
            height: 66px;
        }
        @media (max-width: 604px) {
            bottom: -26px;
            left: 40px;
        }

        img {
            width: 100%;
            border-radius: 50%;
            height: 100%;
            object-fit: cover;
        }

        .hover_info {
            color: black;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            z-index: 1;
            background-color: rgba(213, 197, 236, 0.9);
            opacity: 0;
            transition: 0.3s all;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                white-space: nowrap;
                font-size: 12px;
                @media (max-width: 768px) {
                    font-size: 10px;
                }
            }
        }

        &:hover .hover_info {
            opacity: 1;
        }

        .icon_for_photo {
            z-index: 2;
            position: absolute;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--palette-dark-violet-300);
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0;
            right: 0;
            @media (max-width: 764px) {
                bottom: -8px;
                right: -6px;
                width: 25px;
                height: 25px;
                .icon {
                    width: 20px;
                }
            }
        }
    }
}

.react-responsive-modal-modal {
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 768px) {
    .react-responsive-modal-modal {
        margin: 0 !important;
    }
}
