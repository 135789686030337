.booking-wrapper {
    .head {
        display: flex;
        gap: 15px;
        align-items: center;
        margin-bottom: 50px;
    }

    &__card {
        //cursor: pointer;
        border-radius: 8px;
        background-color: #fefefe;
        width: 100%;
        min-width: 395px;
        min-height: 202px;
        margin-bottom: 10px;

        @media (max-width: 720px) {
            width: 100%;
            min-width: 300px;
        }
        @media (max-width: 360px) {
            width: 315px;
            min-width: 90%;
            min-height: 296px;
        }
        .sectionImgName {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 20px;
            border-bottom: 1px solid #e3d8f3;
            @media (max-width: 580px) {
                display: block;
            }
            .imgHeader {
                position: relative;
                display: flex;
                align-items: center;
                img {
                    position: absolute;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    margin-right: 10px;
                    object-fit: cover;
                    top: -50px;
                    @media (max-width: 580px) {
                        width: 70px;
                        height: 70px;
                    }
                }
                .name_and_status {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    padding-left: 120px;
                    p {
                        font-size: 24px;
                        font-weight: 600;
                    }
                    @media (max-width: 580px) {
                        padding-left: 0;
                        padding-top: 30px;
                    }
                }
                .booked,
                .canceled,
                .declined,
                .completed {
                    background-color: #28c76f;
                    padding: 3px 9px;
                    border-radius: 17px;
                    color: #fff;
                    font-size: 12px;
                    border-style: none;
                    text-transform: capitalize;
                }

                .canceled {
                    background-color: #ee3114 !important;
                }
                .declined {
                    background-color: #f26022 !important;
                }
                .completed {
                    background-color: #ac8ada !important;
                }
            }
            div:nth-child(2) {
                display: flex;
                align-items: center;
                border-radius: 17px;
                font-size: 16px;
                color: #848286;
                padding-right: 10px;
                span {
                    white-space: nowrap;
                }
                .chat {
                    background-color: #582e90;
                    color: white;
                    padding: 5px;
                    margin-left: 10px;
                }
            }
        }
        .sectionInfo {
            padding: 12px 20px;
            display: flex;
            border-bottom: 1px solid #e3d8f3;
            > div {
                width: 100%;
                display: flex;
                @media (max-width: 850px) {
                    display: block;
                }
                .sectionInfo__sub {
                    padding: 2px 30px;
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    color: #582e90;
                    font-size: 16px;
                    border-right: 1px solid #e3d8f3;
                    &.address {
                        flex-shrink: unset !important;
                    }
                    @media (max-width: 850px) {
                        border-right: none;
                    }
                    img {
                        padding-right: 10px;
                    }
                }
            }
        }
        .sectionOnePrice {
            padding: 0 20px 20px 20px;
            border-bottom: 1px solid #e3d8f3;
            div {
                margin-top: 20px;
                p {
                    font-weight: 400;
                    font-size: 18px;
                }
                span {
                    color: #848286;
                }
            }
        }
        .sectionPrices {
            padding: 20px;
            display: flex;
            color: var(--palette-dark-violet-300);
            justify-content: space-between;
            font-size: 20px;
            font-weight: 600;
            p {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    &__btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    &__undertext {
        width: 45%;
        @media (max-width: 1024px) {
            width: 75%;
        }
        @media (max-width: 550px) {
            width: 90%;
        }
        > p {
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
    &__btn2 {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 10px;
        .primary-button_outlined {
            border-color: var(--palette-secondary-red);
            color: var(--palette-secondary-red);
            &:hover {
                color: #fefefe;
                background-color: var(--palette-secondary-red);
                border: 1px solid var(--palette-secondary-red);
            }
        }
    }
    .treatment-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-info {
            span {
                font-size: 16px;
                color: var(--palette-secondary-500);
            }
        }
    }
}
