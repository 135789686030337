.ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    list-style-type: none;
    margin-top: 50px;
    margin-bottom: 50px;
    user-select: none;
    gap: 15px;
    @media (max-width: 768px) {
        gap: 0;
    }
    &-prev .customBtn {
        cursor: pointer;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: var(--palette-secondary-700);
        margin-right: 15px;
    }
    &-next .customBtn {
        cursor: pointer;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: var(--palette-secondary-700);
        margin-left: 15px;
    }
    &-next:hover .customBtn {
        color: var(--palette-dark-violet-300);
        cursor: pointer;
    }
    &-prev:hover .customBtn {
        color: var(--palette-dark-violet-300);
        cursor: pointer;
    }
    &-disabled {
        opacity: 0.8;
        cursor: no-drop;
        pointer-events: none;
    }
    &-item {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: var(--palette-secondary-700);
        width: 30px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 300ms all;
        a {
            transition: 0.2s all;
        }
        &-active {
            border-bottom: 3px solid var(--palette-dark-violet-300);
            align-items: flex-end;
            pointer-events: none;

            a {
                font-size: 28px;
                line-height: 28px;
                color: var(--palette-dark-violet-300);
            }
            &:hover {
                cursor: default;
            }
        }
        &:hover {
            cursor: pointer;
            a {
                color: var(--palette-dark-violet-300);
                transform: scale(1.2);
            }
        }
        &-ellipsis {
            font-size: 6px;
            letter-spacing: 1.5px;
            top: 2px;
            width: 20px;
            position: relative;
            height: auto;
            color: var(--palette-secondary-600);
        }
    }
    .anticon {
        display: none;
    }

    .ant-pagination-item-link {
        border: none;
        pointer-events: none;
    }
    .ant-pagination-item-before-jump-next {
        @media (max-width: 550px) {
            display: none;
        }
    }
}
