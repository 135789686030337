.location-input-group-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 7px;

    .location-input-group {
        display: flex;
        align-items: center;
        flex-direction: row;
        outline: none;
        min-height: 44px;
        background-color: transparent;
        padding: 9px 19px;
        border: 1px solid var(--palette-light-violet-700);
        border-radius: 10px;
        width: 100%;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        gap: 5px;

        .choose-button {
            display: inherit;
            border: none;
            background: none;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            min-width: 71px;
            letter-spacing: 0.12em;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: var(--palette-dark-violet-300);
            cursor: pointer;
        }
        @media (max-width: 360px) {
            width: 100% !important;
        }

        &::placeholder {
            color: #938f8c;
        }

        &:hover {
            border-color: var(--palette-dark-violet-300);
        }

        &:focus {
            border-color: var(--palette-dark-violet-500);
        }

        input {
            flex-grow: 2;
            border: none;
            cursor: pointer;
            font-size: 12px;
        }

        input:focus {
            outline: none;
        }
    }
}
