.modal-to-logOut {
  display: flex;
  padding-top: 15px;
  flex-direction: column;
  // justify-content: space-between;
  gap: 30px;

  h3 {
    margin-top: 20px;
    color: var(--palette-secondary-600);
    text-align: center;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
}