.myWorksPage {
    min-height: 100vh;
    .title-and-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;

        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
        @media (max-width: 550px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            a {
                width: 100%;
            }
            .primary-button {
                width: 100%;
                letter-spacing: 0.12em;
                font-size: 14px;
                padding: 7px;
            }
            h2 {
                font-weight: 400;
            }
        }
    }
    .filters {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        p {
            grid-column: 1 / span 2;
        }
        @media (max-width: 768px) {
            grid-template-columns: 100%;
            gap: 10px;

            p {
                grid-column: 1;
                font-size: 16px;
                line-height: 16px;
            }
            .input-primary-wrap {
                margin-top: 10px;
            }
        }
    }
    .works {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
        // grid-template-columns: repeat(2, 1fr);

        gap: 20px;
        margin-top: 20px;
        margin-bottom: 80px;
        @media (min-width: 1300px) {
            grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
        }
    }
}
