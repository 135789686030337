.photo-input-wrapper {
    .photo-input {
        width: 100%;
        aspect-ratio: 3/2;
        border-radius: 15px;
        border: 1px dashed #292929;
        user-select: none;
        pointer-events: none;
        opacity: 0.5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        p {
            font-size: 14px;
        }
        @media (max-width: 950px) {
            border-radius: 30px;
            display: none;

            &.first-mobile {
                display: flex;
            }
        }

        &.active {
            cursor: pointer;
            opacity: 1;
            pointer-events: all;
            background-color: var(--palette-light-violet-100);
        }
    }
    .work-photo {
        width: 100%;
        aspect-ratio: 3 /2;
        border-radius: 15px;

        position: relative;
        @media (max-width: 950px) {
            border-radius: 30px;
        }
        &_main {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            object-fit: cover;
            position: absolute;
            z-index: 1;
            @media (max-width: 950px) {
                border-radius: 30px;
            }
        }
        &_big {
            z-index: 400;
            width: 300%;
            aspect-ratio: 3/2;
            object-fit: cover;
            border-radius: 30px;
            position: absolute;
            left: -50%;
            bottom: 103%;
            pointer-events: none;
            opacity: 0;
            transition: 0.2s all;

            @media (max-width: 1024px) {
                left: 0;
            }
            @media (max-width: 950px) {
                display: none;
            }
        }
        .change-modal {
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 30px;
            padding: 10px;
            border-radius: 15px;
            background-color: rgba(0, 0, 0, 0.691);
            opacity: 0;
            pointer-events: none;
            transition: 0.2s;
            @media (max-width: 1024px) {
                gap: 20px;
            }
            @media (max-width: 950px) {
                padding-bottom: 40px;
                gap: 70px;
            }
            @media (max-width: 550px) {
                padding-bottom: 15px;
                gap: 40px;
            }
        }

        &:hover .change-modal,
        &:hover .work-photo_big {
            opacity: 1;
            pointer-events: all;
        }
    }
    &:nth-child(4) {
        .work-photo_big {
            left: -150%;
        }
    }
    &:nth-child(5) {
        .work-photo_big {
            left: -200%;
        }
    }
    @media (max-width: 1024px) {
        &:nth-child(4),
        &:nth-child(5) {
            .work-photo_big {
                left: -200%;
            }
        }
    }
    .viewAll .icon {
        transform: rotate(90deg);
    }
    .viewAll {
        justify-content: center;
        margin-top: 10px;
    }
    .more-photos {
        display: none;
        &.active {
            display: block;
        }
    }
}
