.viewAll {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    gap: 5px;
    cursor: pointer;
    &:hover p {
        color: var(--palette-dark-violet-300);
    }
    p {
        font-size: 14px;
        margin-top: 2px;
        font-weight: 600;
        height: 19px;
        letter-spacing: 0.12em;
    }
}
