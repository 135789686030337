.room-basic-info-wrapper {
    .basic-info-room {
        background: #fefefe;
        border-radius: 10px;
        padding: 35px;
        @media (max-width: 360px) {
            padding: 20px;
        }

        h4 {
            color: var(--palette-secondary-600);
            font-weight: 500;
            margin-left: 1px;
            margin-bottom: 6px;
            @media (max-width: 768px) {
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
            }
        }

        &__grid-view {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-bottom: 30px;
            margin-top: 130px;
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
        }

        .input-group-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            gap: 7px;
            .choose-button {
                display: none;
            }
            @media screen and (max-width: 768px) {
                h4 {
                    display: flex;
                    align-items: center;
                }
                .choose-button {
                    display: inherit;
                    border: none;
                    background: none;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    letter-spacing: 0.12em;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: var(--palette-dark-violet-300);
                    cursor: pointer;
                    margin-left: 10px;
                }
            }

            .input-group {
                display: flex;
                flex-direction: row;
                outline: none;
                background-color: transparent;
                padding: 9px 19px;
                border: 1px solid var(--palette-light-violet-700);
                border-radius: 10px;
                width: 100%;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                @media (max-width: 360px) {
                    width: 93% !important;
                }

                &::placeholder {
                    color: #938f8c;
                }

                &:hover {
                    border-color: var(--palette-dark-violet-300);
                }

                &:focus {
                    border-color: var(--palette-dark-violet-500);
                }

                input {
                    flex-grow: 2;
                    border: none;
                }

                input:focus {
                    outline: none;
                }
            }
        }
    }
}

//.delete-account {
//    cursor: pointer;
//    font-style: normal;
//    font-weight: 400;
//    font-size: 14px;
//    line-height: 19px;
//    color: var(--palette-dark-violet-300);
//    border: none;
//    background: none;
//    margin: 30px 0;
//    &:hover {
//        color: var(--palette-dark-violet-100);
//    }
//}

.photo-zone-room {
    //position: relative;
    display: inline;
    .userProfile_photo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        border: 1px solid var(--palette-dark-violet-300);
        object-fit: fill;
        background-color: white;
        position: absolute;
        cursor: pointer;
        img {
            width: 100%;
            border-radius: 50%;
            height: 100%;
            object-fit: cover;
        }

        .hover_info {
            color: black;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            z-index: 1;
            background-color: rgba(213, 197, 236, 0.9);
            opacity: 0;
            transition: 0.3s all;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                white-space: nowrap;
                font-size: 12px;
            }
        }

        &:hover .hover_info {
            opacity: 1;
        }

        .icon_for_photo {
            z-index: 2;
            position: absolute;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--palette-dark-violet-300);
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0;
            right: 0;
            // @media (max-width: 764px) {
            //   bottom: -8px;
            //   right: -6px;
            //   width: 25px;
            //   height: 25px;
            // }
        }
    }
}
