.forgot-password-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 50%;
    margin-left: 50%;
    height: 100vh;
    padding: 20px;
    padding-top: 100px;
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
    }
    .loginPage_info {
        height: 100vh;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 100;
        width: 50%;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        transition: 0.8s all ease-in-out;
        @media (max-width: 768px) {
            display: none;
        }
        * {
            transition: 0.8s left ease-in-out;
        }

        & > img {
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: -20vw;
        }

        .info_container {
            width: 100vw;
            height: 100vh;
            display: grid;
            grid-template-columns: 50% 50%;
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;

            .information {
                display: flex;
                flex-direction: column;
                gap: 30px;
                align-items: center;
                margin: 0 auto;
                transition: 2s opacity;
                h2 {
                    font-size: 40px;
                }
                & > p {
                    margin-top: 15px;
                }

                .icon {
                    margin-top: 70px;
                }
            }
            .for_sign-in {
                opacity: 0;
            }
            .for_sign-up {
                opacity: 1;
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 700px;
        align-items: center;
    }
}
