.workingHours_page {
  .workingHours_title {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    gap: 10px;
  }
  .workingHours_card {
    border-radius: 10px;
    background-color: #fefefe;
    padding: 10px 0 35px 0;
    @media (max-width: 768px) {
      padding: 10px 0 20px 0;


    }
  }
  .workingHours_btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding: 20px 35px 0 20px;
    @media (max-width: 768px) {
      gap: 10px;
      padding: 10px 20px 0 20px;

    }
  }
}