.status-team {
    border: 1px solid transparent;
    border-radius: 17px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    padding: 1px 9px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.action-dropdown-container-team {
    //margin-top: 5px;
    position: absolute;
    z-index: 3;
    background: #fefefe;
    width: 89px;
    overflow-x: visible !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    //left: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    span {
        cursor: pointer;
        padding: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        &:hover {
            background: var(--palette-light-violet-700);
            color: #fefefe;
        }
    }
}
