.document-form{
  background: #FEFEFE;
  border-radius: 10px;
  padding: 35px;
  h6{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin: 10px 0;
    @media (max-width: 768px){
      font-size: 14px;
      margin: 5px 0;
    }
  }

  .date{
    display: flex;
    gap: 30px;
    margin-top: 23px;
    margin-bottom: 20px;
    @media (max-width: 768px){
      flex-direction: column;
      gap: 20px;
      margin-bottom: 10px;
    }
  }

  .additional-doc{
    margin-top: 15px;
  }

  .add-more-btn{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: var(--palette-dark-violet-300);
    border: none;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 14px;
  }
  .upload-document-input{
    margin-bottom: 29px;
  }

}
  .onboarding-group-btn{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin: 30px 0;
  }
