.loader {
    width: 100%;
    // position: fixed;
    // top: 0;
    z-index: 100;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--palette-secondary-main-bg);
    &.transparent {
        background-color: transparent !important;
    }
    &.full {
        position: fixed;
        top: 0;
        z-index: 1000;
    }
    &.local {
        min-height: auto;
        background-color: transparent;
    }
    #container {
        width: 120px;
        height: 120px;

        @keyframes animation {
            0% {
                stroke-dasharray: 1 98;
                stroke-dashoffset: -105;
            }
            50% {
                stroke-dasharray: 80 10;
                stroke-dashoffset: -160;
            }
            100% {
                stroke-dasharray: 1 98;
                stroke-dashoffset: -300;
            }
        }

        #spinner {
            fill: transparent;
            stroke: var(--palette-dark-violet-100);
            stroke-width: 7px;
            stroke-linecap: round;
            // filter:url(#shadow);
            transform-origin: center;
            animation-name: animation;
            animation-duration: 1.5s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
    }
}
