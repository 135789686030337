.account-tab-room {
    // padding-top: 128px;
    display: flex;
    gap: 50px;
    margin-bottom: 35px;
    @media (max-width: 460px) {
        gap: 40px;
        overflow: auto;
    }
    li {
        list-style-type: none;
        button {
            cursor: pointer;
            background: none;
            font-family: 'Comfortaa', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 27px;
            color: #3a393a;
            border: 1px solid transparent;
            @media (max-width: 953px) {
                font-size: 17px;
            }
            @media (max-width: 360px) {
                font-weight: 700;
                font-size: 12px;
                line-height: 13px;
            }
            &:hover {
                padding-bottom: 5px;
                border-bottom: 2px solid var(--palette-dark-violet-300);
            }
        }
        .active {
            font-family: 'Comfortaa', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 27px;
            padding-bottom: 5px;
            color: var(--palette-dark-violet-300);
            border-bottom: 2px solid var(--palette-dark-violet-300);
            @media (max-width: 953px) {
                font-size: 17px;
            }
            @media (max-width: 360px) {
                font-weight: 700;
                font-size: 12px;
                line-height: 13px;
            }
        }
    }
}

.tab-content {
    display: none;
}

.active-content {
    display: block;
}
