.treatment_accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .treatment_accordion_element {
        padding: 10px;
        background: #fefefe;

        .element_title {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 5px;
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 600;
            }

            .clients__title__active {
                white-space: unset;
                text-overflow: unset;
            }
            .icon {
                transition: 0.3s all;
            }
            .icon-rotate {
                transform: rotate(180deg);
            }
        }
        .treatment_accordion_info {
            opacity: 0;
            user-select: none;
            pointer-events: none;
            transition: height 0.2s;
            height: 0;
            .time {
                font-size: 14px;
            }
            .treatmentList_price {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
        .active-accordion {
            transition: height 0.2s, opacity 0.3s 0.1s;
            opacity: 1;
            display: flex;
            user-select: auto;
            pointer-events: all;
            flex-direction: column;
            height: auto;
        }

        .clientsInfo {
            padding-left: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-content: flex-start;
            font-size: 14px;
            &__header {
                width: 150px;
            }
        }

        .clientsPhoto,
        .defaultClientsPhoto {
            width: 30px;
            border-radius: 15px;
            margin-right: 10px;
        }
        .accButtons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;

            .clients-btn {
                width: 200px;
                padding: 6px 20px;
                background-color: #582e90;
                border-radius: 10px;
                color: #fefefe;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}
