.room-slider {
    width: calc(100% - 108px) !important;
    margin: 0 54px !important;
    @media (max-width: 630px) {
        width: 100% !important;
        margin: 0 !important;
    }

    .slick-list {
        border-radius: 9px !important;
    }
    //.slick-track {
    //    display: flex;
    //    border-radius: 21px;
    //    //gap: 20px;
    //    justify-content: center;
    //    //@media (max-width: 768px) {
    //    //    gap: 15px;
    //    //}
    //}

    .slick-dots {
        bottom: 16px !important;
        pointer-events: none;
        li {
            width: 10px;
            height: 10px;
            margin: 0 3px;
        }

        button {
            width: 10px;
            height: 10px;
            padding: 0px;
            border-radius: 50%;
            border: 1px solid var(--palette-light-violet-100);
            color: transparent;
            background-color: var(--palette-dark-violet-300);
            opacity: 1;
            transition: 0.3s all;
        }
        button::before {
            content: none;
            font-size: 0;
            display: none;
        }
        .slick-active button {
            background-color: var(--palette-light-violet-100);
            border: 1px solid var(--palette-dark-violet-300);

            opacity: 1;
        }
    }
    .custom-arrow {
        width: 38px;
        height: 38px;
        background: #fbf9fb;
        box-shadow: 2px 2px 20px rgb(144 99 205 / 50%);
        border-radius: 50%;
        position: absolute !important;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            width: 32px;
            height: 32px;
        }
        div {
            width: inherit;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-next {
            right: -54px;
            top: 45%;
            @media (max-width: 1024px) {
                right: -9%;
                top: 43%;
                z-index: 3;
            }
        }

        &-prev {
            left: -54px;
            top: 45%;
            @media (max-width: 1024px) {
                left: -9%;
                top: 43%;
                z-index: 3;
            }
        }

        &:hover {
            background: #582e90;
            box-shadow: 2px 2px 20px rgba(144, 99, 205, 0.5);
            cursor: pointer;
        }
    }
}
