.cookie-container {
  position: fixed;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  align-items: center;
  bottom: 0;
  z-index: 99999;
  left: 0;
  gap: 20px;
  right: 0;
  box-shadow: 1px -1px 5px #848286;
  justify-content: space-between;

  .button {
    width: 220px;
  }

  .title {
    font-family: 'Comfortaa',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--secondary-dark-grey);
  }

  .text {
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--secondary-dark-grey);
  }

  .button_text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

  Button {
    display: flex;
    align-items: center;
    height: 42px;
  }
  @media (max-width: 768px) {
    gap: 10px;
    width: 330px;
    height: 366px;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
.close {
  position: absolute;
  top: 20px;
  right: 20px;
}
    .button {
      margin-top: 28px;
    }

    .button_text {
      font-family: 'Nunito Sans',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .button_decline {
      display: none;
    }


    Button {
      width: 230px !important;
    }
  }
}

