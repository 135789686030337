.review-card {
    width: 100%;
    background: #fefefe;
    border-radius: 8px;
     border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;
    &.detailed {
        border: 1px solid var(--palette-dark-violet-100);
        cursor: alias;
    }
     &:hover {
         border: 1px solid var(--palette-dark-violet-100);
         @media (max-width: 768px) {
             &:not(.detailed){
                 border: 1px solid transparent;
             }
         }
     }
    .review-card_top {
        border-bottom: 1px solid #e3d8f3;
    }
    .review-padding {
        padding: 20px;
    }
    .review-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;
        .review-author {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .review-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .review-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap;
        @media (max-width: 768px) {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
        }
    }

    .review-footer {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        div span {
            font-style: normal;
            padding-right: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: var(--palette-secondary-500);
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
            }
        }
        .status {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: var(--palette-secondary-500);
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.09em;
            }
        }
    }
}
