.no-approve {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  img {
    max-width: 100%;
  }
  h3 {
    padding: 30px 0 15px 0;
  }
  p {
    text-align: center;
    max-width: 365px;
  }
  .bottom-text {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}