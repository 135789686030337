.layout {
    display: flex;

    .layout-content {
        width: 100%;
        @media (min-width: 1024px) {
            width: calc(100vw - 80px);
            &.big {
                width: calc(100vw - 280px);
            }
        }
        display: flex;
        flex-direction: column;
    }
}
