.footer-box {
    bottom: 0;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 481px) {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    div {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--palette-secondary-500);
        @media (max-width: 360px) {
            font-weight: 400;
            font-size: 9px;
            line-height: 12px;
        }
    }
    .right-footer {
        display: flex;
        align-items: center;
        gap: 9px;

        .heart-icon{
            cursor: auto;
        }
    }
}
