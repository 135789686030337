.masterWorksCard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    // max-width: 600px;
    border: 1px solid transparent;
    background-color: white;
    // overflow: hidden;
    border-radius: 8px;

    &_slider {
        width: 100%;
        aspect-ratio: 3/2;

        .slider_img {
            width: 100%;
            aspect-ratio: 3/2;
            object-fit: cover;
        }
    }

    &_title {
        margin-top: 5px;
        padding: 0 20px;
        b {
            font-size: 0.9rem;
        }
        display: flex;
        justify-content: space-between;
        .edit-work {
            position: relative;

            &_options {
                // margin: 40px;
                outline: 40px transparent !important;
                width: 150px;
                z-index: 2;
                display: flex;
                flex-direction: column;
                background-color: var(--palette-light-violet-100);
                border-radius: 10px;
                overflow: hidden;
                height: 0px;
                transition: 0.3s all;
                position: absolute;
                top: 24px;
                right: 0;

                &.isShown {
                    height: 84px;
                    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
                }
                & > span,
                a {
                    cursor: pointer;
                    user-select: none;
                    padding: 10px 20px;
                    transition: 0.2s all;
                    font-size: 16px;
                    line-height: 22px;

                    &:hover {
                        background-color: var(--palette-light-violet-200);
                    }
                }
            }
        }
    }

    &_description {
        display: flex;
        width: 100%;
        margin-top: auto;
        min-height: 36px;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        //overflow: hidden;
        padding: 0 20px;
        padding-bottom: 20px;

        .text {
            overflow: hidden;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
        & > p {
            font-size: 0.8rem;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
}

.masterWorksCard:hover {
    border: 1px solid #582e90;
    transition: 0.5s;
}

.deleteModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80vw;
    max-width: 600px;
    align-items: flex-start;

    .delete-btns {
        display: flex;
        gap: 20px;
        width: 100%;
        justify-content: flex-end;
        .primary-button_outlined {
            border-color: var(--palette-secondary-red);
            color: var(--palette-secondary-red);
            &:hover {
                color: #fefefe;
                background-color: var(--palette-secondary-red);
                border: 1px solid var(--palette-secondary-red);
            }
        }
    }
}
