.onboarding-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 452px;
  @media (max-width: 768px) {
    width: 309px;
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    @media (max-width: 768px) {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #848286;
    margin: 14px 0;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .line {
    width: 100%;
    border: 1px solid #E3D8F3;
    margin: 20px 0;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}