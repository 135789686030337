$palettes: (
    light-violet: (
        700: #9e77d3,
        600: #ac8ada,
        500: #ba9ee0,
        400: #c8b1e6,
        300: #d5c5ec,
        200: #e3d8f3,
        100: #f1ecf9,
    ),
    dark-violet: (
        700: #12091d,
        600: #23123a,
        500: #351c56,
        400: #462573,
        300: #582e90,
        200: #6937ad,
        100: #7c46c4,
    ),
    secondary: (
        700: #292929,
        600: #3a393a,
        500: #848286,
        400: #bbbbbb,
        300: #dfdfdf,
        200: #fefefe,
        100: #ffffff,
        main-bg: #f7f4f9,
        yellow: #f4c038,
        red: #ff2200,
        opacity-black: #1c1c1c,
        opacity-white: #fbf9fb,
    ),
);

:root {
    @each $colorname, $palette in $palettes {
        @each $key, $value in $palette {
            $keyname: '--palette-' + $colorname + '-' + $key;
            #{$keyname}: #{$value};
        }
    }
}
