.add-doc-modal {
    width: 100%;
    max-width: 645px !important;

    .add-docs-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
        width: 100%;

        .upload-doc-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            gap: 7px;

            .refresh-input {
                padding: 5px 15px;
                font-weight: 400;
                font-size: 12px;
            }

            .upload-doc-label {
                color: var(--palette-secondary-600);
                font-weight: 500;
                margin-left: 1px;
            }

            //.document_title {
            //    color: var(--palette-secondary-600);
            //    font-weight: 500;
            //    font-size: 15px;
            //}

            .doc-upload {
                display: flex;
                cursor: pointer;
                min-height: 45px;
                outline: none;
                background-color: transparent;
                padding: 9px 19px;
                border: 1px solid var(--palette-light-violet-700);
                border-radius: 10px;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .upload-txt {
                    overflow: hidden;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    text-align: right;
                    letter-spacing: 0.12em;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: #582e90;
                }

                .upload-txt-none {
                    display: none;
                }

                .upload-placeholder {
                    overflow: hidden;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 25px;
                    color: #848286;
                    @media (max-width: 540px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }

        .document-category-label {
            color: var(--palette-secondary-600);
            font-weight: 500;
        }

        .document_title {
            word-break: break-word;
            width: 100%;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #848286;
            @media (max-width: 540px) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .input-select-cat {
            cursor: pointer;
            background-position: calc(100% - 10px) 50% !important;
            padding-right: 30px;
        }

        .input-primary-wrap .input-primary__withIcon {
            padding-left: 18px !important;
        }

        .docs-btn {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
        }

        .select-box {
            position: relative;

            .select-option-container {
                z-index: 1;
                background: #ffffff;
                border-radius: 10px;
                position: absolute;
                top: 79px;
                width: 100%;
                max-height: 170px;
                overflow-y: auto;
                box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
                transition: 0.3s all;

                .select-option {
                    cursor: pointer;
                    padding: 10px 10px;
                    margin: 0;

                    &:hover {
                        background-color: var(--palette-light-violet-400);
                        color: #fefefe;
                    }

                    transition: 0.2s all;

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--palette-light-violet-400);
                    }
                }
            }
        }

        .expiration-date {
            display: flex;
            gap: 20px;
            justify-content: flex-start;
            width: 60%;
            @media (max-width: 564px) {
                flex-direction: column;
                width: 100%;
            }
        }
    }

    .modal-header {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 22px;
        }
        @media (max-width: 360px) {
            font-size: 18px;
            line-height: 20px;
        }
    }
}
